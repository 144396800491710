import React, { useEffect, useRef, useState } from "react";
import { initializeApp } from "firebase/app";
import "./chatroom.css";
// import { Firestore } from "firebase/firestore";
import "firebase/firestore";
// import "firebase/auth";
// import "firebase/analytics";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { getFirestore } from "firebase/firestore";
import { ReactComponent as ArrowIcon } from "../../../assets/signin/right.svg";

import { v4 as uuidv4 } from "uuid";
import {
  collection,
  doc,
  setDoc,
  query,
  orderBy,
  where,
} from "firebase/firestore";

const app = initializeApp({
  apiKey: "AIzaSyAWXlR6zyIlc_HKmpBcEzVAv7-dbBEI-H0",
  authDomain: "newstorage-d1969.firebaseapp.com",
  projectId: "newstorage-d1969",
  storageBucket: "newstorage-d1969.appspot.com",
  messagingSenderId: "738755919670",
  appId: "1:738755919670:web:080f2524d38f9e7a73bd97",
  measurementId: "G-FJ4VB08ZE6",
});
const db = getFirestore(app);

export default function ChatRoom(props) {
  const dummy = useRef();
  const firstdummy = useRef();
  //   const messagesRef = db.collection("messages");
  const messagesRef = collection(db, "messages");
  //   const query = messagesRef.orderBy("createdAt").limit(25);

  const qu = query(messagesRef, orderBy("createdAt"));

  const [messages] = useCollectionData(qu, { idField: "id" });

  const [formValue, setFormValue] = useState("");
  useEffect(() => {
    dummy.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const sendMessage = async (e) => {
    e.preventDefault();

    // const { uid, photoURL } = auth.currentUser;

    await setDoc(doc(messagesRef, uuidv4()), {
      text: formValue,
      //   createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      createdAt: Date.parse(new Date()),
      uid: props.selectedMember._id,
      organisation_id: props.selectedMember.organisation_id,
      member: props.selectedMember,
      photoURL: props.selectedMember.imageUrl,
    });

    setFormValue("");
    setTimeout(() => {
      dummy.current.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  };
  return (
    <>
      <main className="chatroom-list">
        {messages &&
          messages.map(
            (msg) => {
              return msg.organisation_id ===
                props.selectedMember.organisation_id &&
                new Date(msg.createdAt).getMonth() === new Date().getMonth() ? (
                <ChatMessage
                  key={msg.id}
                  message={msg}
                  selectedMember={props.selectedMember}
                />
              ) : null;
            }
            //  &&
          )}
        {/* {idx === messages.length?
        <span ref={firstdummy}></span>:null} */}
        <span ref={dummy}></span>
      </main>

      <form onSubmit={sendMessage} className="chatroom-form">
        <input
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder="Message"
        />

        <button type="submit" disabled={!formValue}>
          <ArrowIcon />
        </button>
      </form>
    </>
  );
}

function ChatMessage(props) {
  const { text, uid, photoURL, member } = props.message;

  const messageClass = uid === props.selectedMember._id ? "sent" : "received";

  return (
    <>
      <div className={`message ${messageClass}`}>
        <img
          src={
            photoURL || "https://api.adorable.io/avatars/23/abott@adorable.png"
          }
        />
        <div className="message-right">
          <span>
            {member?.first_name || "-"} {member?.last_name || ""}
          </span>
          <p>{text}</p>
        </div>
      </div>
    </>
  );
}
