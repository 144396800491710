import React, { useEffect, useRef, useState } from "react";
import "./members.css";
import axiosInstance from "../../../helpers/axiosInstance";
import AddMember from "./addmember/Addmember";

import { ReactComponent as EditLogo } from "../../../assets/signin/edit.svg";
import UserIcon from "../../../assets/signin/user.svg";
import useAuth from "../../../hooks/useAuth";
import MainLoader from "../../../shared/mainloader/MainLoader";
import MemberDetailContainer from "./memberDetails/memberdetailcontainer/MemberDetailContainer";
import { AnimatePresence, motion } from "framer-motion";

function Members() {
  const { auth, organisation } = useAuth();
  const [membersList, setMembersList] = useState([]);
  const [selectedMember, setSelectedMember] = useState({});
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      getMembersList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMembersList = () => {
    setLoading(true);
    axiosInstance
      .get(`/api/member/${organisation._id}/getallmembers`)
      .then((response) => {
        setLoading(false);
        setMembersList(
          response.data.filter((member) => member.role !== "super-admin")
        );
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div className="members">
      <div className="members-wrapper">
        <div className="members-header">
          <div className="members-headerleft">
            <h3
              className="members-heading"
              onClick={() => {
                if (
                  (auth?.user?.role === "admin" ||
                    auth?.user?.role === "super-admin" ||
                    auth?.user?.role === "president") &&
                  selectedOption
                ) {
                  setSelectedMember({});
                  setSelectedOption("");
                }
              }}
            >
              Members List
            </h3>
            {selectedOption && <h3 className="members-heading">{">"}</h3>}
            {selectedOption === "member-detail" ? (
              <h3 className="members-heading">
                {selectedMember.first_name} {selectedMember.last_name}
              </h3>
            ) : selectedOption === "add-member" ? (
              <h3 className="members-heading">Add Member</h3>
            ) : null}
          </div>
          <div className="members-headerright">
            {auth?.user?.role !== "member" &&
            auth?.user?.role !== "president" &&
            auth?.user?.role !== "secretary" &&
            !selectedOption ? (
              <button
                onClick={() => setSelectedOption("add-member")}
                className="members-headerright-btn"
              >
                Add Member
              </button>
            ) : null}
          </div>
        </div>
        <section className="members-content">
          {selectedOption === "add-member" ? (
            <AddMember
              updateParent={() => {
                getMembersList();
                setSelectedOption("");
              }}
            />
          ) : selectedOption === "member-detail" ? (
            <MemberDetailContainer
              selectedMember={selectedMember}
              updateParent={getMembersList}
              goBack={() => {
                getMembersList();
                setSelectedOption("");
              }}
            ></MemberDetailContainer>
          ) : loading ? (
            <MainLoader />
          ) : !membersList?.length ? (
            <p>There are no members.</p>
          ) : (
            <main className="members-gridWrapper">
              {membersList
                ?.filter(
                  (member) =>
                    member.role !== "admin" && member.role !== "super-admin"
                )
                ?.map((member, idx) => {
                  return (
                    <motion.section
                      className="members-gridcard"
                      key={idx}
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      {auth?.user?.role !== "member" &&
                      auth?.user?.role !== "secretary" ? (
                        <EditLogo
                          className="members-edit"
                          onClick={() => {
                            setSelectedMember(member);
                            setSelectedOption("member-detail");
                          }}
                        />
                      ) : null}
                      <img
                        className="members-user"
                        src={member?.imageUrl || UserIcon}
                        alt="profile"
                      />
                      {/* <UserIcon /> */}
                      <div className="members-card-form">
                        <label className="members-card-formheader">
                          {member?.first_name} {member?.last_name}
                        </label>
                        <label className="members-card-formlabel">
                          {member?.email}
                        </label>
                        <label className=" members-card-formlabel members-card-formlabel-role">
                          {member?.role}
                        </label>
                      </div>
                    </motion.section>
                  );
                })}
            </main>
          )}
        </section>
      </div>
    </div>
  );
}

export default Members;
