import React, { useRef, useState } from "react";
import "./signin.css";
import { ReactComponent as MainLogo } from "../../assets/signin/Logo.svg";
import axiosInstance from "../../helpers/axiosInstance";
import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../shared/loading/Loading";

function Signin(props) {
  const [loading, setLoading] = useState(false);
  const { setAuth, setOrganisation } = useAuth();
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const navigate = useNavigate();
  // const location = useLocation();
  const from = "/admin/home";

  function handleSubmit(e) {
    setLoading(true);
    const email = emailRef.current.value.toString().toLowerCase();
    const Logindata = {
      email: email,
      password: passwordRef.current.value,
      fcm_token: props.fcmToken,
    };
    e.preventDefault();
    axiosInstance
      .post("/api/user/signin", Logindata)
      .then((response) => {
        setLoading(false);
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem("user", JSON.stringify(response.data.userModel));
        localStorage.setItem(
          "organisation",
          JSON.stringify({ _id: response.data.userModel?.organisation_id })
        );
        setAuth({
          user: response.data?.userModel,
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
        });
        setOrganisation({ _id: response.data.userModel.organisation_id });
        navigate(from, { replace: true });
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to Login");
        console.log(error);
      });
  }
  return (
    <div className="signin-container">
      <MainLogo className="signin-main-logo" />
      <form className="signin-login-wrapper" onSubmit={handleSubmit}>
        <label>Login</label>
        <div className="signin-login-formsection">
          <input
            autoFocus
            tabIndex="1"
            type="text"
            ref={emailRef}
            placeholder="Enter your registered email ID"
            autoComplete="off"
            name="email"
          />
          <input
            tabIndex="2"
            type="password"
            ref={passwordRef}
            placeholder="Password"
            autoComplete="off"
            name="password"
          />
        </div>
        <div className="signin-forgotpassword-right">
          <label
            onClick={() => {
              navigate("/forgotpassword", { replace: true });
            }}
          >
            Forgot Password
          </label>
        </div>
        <button className="signin-login-btn" disabled={loading}>
          {loading ? <Loading /> : "Sign In"}
        </button>
      </form>
    </div>
  );
}

export default Signin;
