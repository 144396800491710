import React from "react";
import useAuth from "./useAuth";
import { useLocation, Navigate, Outlet } from "react-router-dom";

function RequireOrganisation() {
  const { organisation } = useAuth();
  console.log(organisation)
  const location = useLocation();
  return  !organisation._id ? <Outlet /> : <Navigate to="/admin" state={{ from: location }} replace />;
}

export default RequireOrganisation;
