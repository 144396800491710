import React, { useState, useEffect } from "react";
import "./documents.css";
import AuthorizedContent from "../../../shared/AuthorizedContent";
import useAuth from "../../../hooks/useAuth";
import axiosInstance from "../../../helpers/axiosInstance";
import { ReactComponent as PdfIcon } from "../../../assets/docs/pdf.svg";
import { ReactComponent as DocsIcon } from "../../../assets/docs/docs.svg";
import { ReactComponent as ExcelIcon } from "../../../assets/docs/excel.svg";
import { ReactComponent as PhotosIcon } from "../../../assets/docs/image.svg";
import { ReactComponent as WordIcon } from "../../../assets/docs/word.svg";
import { ReactComponent as KebabIcon } from "../../../assets/kebab.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/signin/right.svg";
import OutsideAlerter from "../../../helpers/outsideAlerter";
import { toast } from "react-toastify";
import FlipLoader from "../../../shared/fliploader/FlipLoader";
import { AnimatePresence, motion } from "framer-motion";

function Documents() {
  const [documentsData, setDocumentsData] = useState([]);
  const [uploadFile, setUploadFile] = useState();
  const [loading, setLoading] = useState(true);
  const [renamefile, setRenamefile] = useState(null);
  const [showrenamefile, setshowRenamefile] = useState(null);
  const [showoptions, setshowOtopns] = useState(null);
  const [showDetails, setshowDetails] = useState(null);
  const { organisation } = useAuth();

  useEffect(() => {
    getalldocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getalldocuments = () => {
    axiosInstance
      .get(`/api/storage/${organisation._id}/getalldocuments`)
      .then((response) => {
        setLoading(false);
        setDocumentsData(response.data.data);
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
      });
  };

  const renderImage = (ext) => {
    switch (ext) {
      case ".pdf":
        return <PdfIcon className="documents-docs-icons" />;
      case ".docx":
        return <WordIcon className="documents-docs-icons" />;
      case ".xlxs":
        return <ExcelIcon className="documents-docs-icons" />;
      case ".jpeg":
      case ".jpg":
      case ".ico":
      case ".png":
      case ".webp":
      case ".svg":
        return <PhotosIcon className="documents-docs-icons" />;
      default:
        return <DocsIcon className="documents-docs-icons" />;
    }
  };

  const handleSubmit = (name) => {
    setLoading(true);
    const newFile = new File([uploadFile], `${name}`, {
      type: uploadFile.type,
    });
    let form = new FormData();
    form.append("file", newFile);
    axiosInstance
      .post(`/api/storage/${organisation._id}/uploaddocument`, form)
      .then((response) => {
        getalldocuments();

        toast("File uploaded successfully.");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast("Unable to upload file!");
      });
  };
  const handleDelete = (id) => {
    setLoading(true);
    axiosInstance
      .delete(`/api/storage/${organisation._id}/deletedocument/${id}`)
      .then((response) => {
        getalldocuments();
        toast("File deleted successfully.");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast("Unable to delete file!");
      });
  };

  const handleRename = (id) => {
    setLoading(true);
    axiosInstance
      .post(`/api/storage/${organisation._id}/renamefile/${id}`, {
        file_name: renamefile,
      })
      .then((response) => {
        getalldocuments();
        setRenamefile(null);
        setshowRenamefile(null);
        toast("File renamed successfully.");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast("Unable to rename file!");
      });
  };

  const renderDetailModal = () => {
    let uploaded_date = new Date(showDetails.uploaded_date).toLocaleDateString(
      "en-us",
      {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      }
    );
    return (
      <section className="documents-detailmodal-container">
        <div className="documents-detailmodal-content">
          <header className="documents-detailmodal-header">
            <ArrowIcon
              className="documents-modal-lefticon"
              onClick={() => setshowDetails(null)}
            />
            Document Details
          </header>
          <main className="documents-detailmodal-body">
            <div className="documents-detailmodal-form">
              <label>File name</label>
              <p>{showDetails?.file_name}</p>
            </div>
            <div className="documents-detailmodal-form">
              <label>Uploaded date</label>
              <p>{uploaded_date}</p>
            </div>
            <div className="documents-detailmodal-form">
              <label>Uploaded by</label>
              <p>
                {showDetails.uploaded_by.first_name}{" "}
                {showDetails.uploaded_by.last_name}
              </p>
            </div>
            <div className="documents-detailmodal-form">
              <label>File Type</label>
              <p>{showDetails.file_type}</p>
            </div>
            <div className="documents-detailmodal-form">
              <label>File size</label>
              <p>{showDetails.file_size}</p>
            </div>
          </main>
          <footer className="documents-detailmodal-footer">
            <button className="documents-detailmodal-footerdwnload">
              <a href={showDetails.file_url}>Download</a>
            </button>
            <AuthorizedContent roles={["super-admin", "admin"]}>
              {" "}
              <button
                className="documents-detailmodal-footerdelete"
                onClick={() => handleDelete(showDetails._id)}
              >
                Delete
              </button>
            </AuthorizedContent>
          </footer>
        </div>
      </section>
    );
  };
  const renderRenameModal = (edit) => {
    return (
      <section className="documents-detailmodal-container">
        <div className="documents-detailmodal-content">
          <header className="documents-detailmodal-header">
            <ArrowIcon
              className="documents-modal-lefticon"
              onClick={() => setshowRenamefile(null)}
            />
            Rename File
          </header>
          <main className="documents-detailmodal-body">
            <div className="documents-detailmodal-form">
              <input
                type="text"
                onChange={(e) => {
                  setRenamefile(e.target.value);
                }}
                value={renamefile}
              />
            </div>
          </main>
          <footer className="documents-detailmodal-renamefooter">
            <button
              className="documents-addbtn "
              onClick={() => {
                showrenamefile._id
                  ? handleRename(showrenamefile._id)
                  : handleSubmit(
                      `${renamefile}.${uploadFile?.name.split(".")[1]}`
                    );
                setshowRenamefile(null);
                setRenamefile(null);
              }}
            >
              Submit
            </button>
          </footer>
        </div>
      </section>
    );
  };

  return (
    <div className="documents-container">
      {showDetails ? renderDetailModal() : null}
      {showrenamefile ? renderRenameModal() : null}
      <div className="home-header">
        <div className="home-headerleft">
          <h3 className="home-heading">Documents</h3>
        </div>
        <AuthorizedContent roles={["super-admin", "admin"]}>
          <label htmlFor="fileupload-docs" className="documents-addbtn">
            <input
              type="file"
              id="fileupload-docs"
              onChange={(e) => {
                setUploadFile(e.target.files[0]);
                setshowRenamefile(e.target.files[0]);
                setRenamefile(e.target.files[0]?.name?.split(".")[0]);
              }}
            />
            Upload
          </label>
        </AuthorizedContent>
      </div>
      <div className="documents-content">
        {loading ? (
          <div className="documents-loader">
            <FlipLoader />
          </div>
        ) : !documentsData?.length ? (
          <p>There are no documents.</p>
        ) : (
          documentsData?.map((docs, idx) => {
            return (
              <motion.div
                className="documents-content-block"
                key={idx}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
              >
                {renderImage(docs.extension)}
                <label>{docs.file_name}</label>

                <div className="documents-content-blockleft">
                  <div
                    className="documents-content-kebabblock"
                    onClick={() => setshowOtopns(docs._id)}
                  >
                    <KebabIcon />
                  </div>
                  {showoptions === docs._id ? (
                    <OutsideAlerter
                      resetAlert={() =>
                        setTimeout(() => {
                          setshowOtopns(null);
                        }, 100)
                      }
                    >
                      <div className="documents-content-blockleft-menu">
                        <button
                          onClick={() => {
                            setshowOtopns(null);
                          }}
                        >
                          <a href={docs.file_url}> Download</a>
                        </button>
                        <AuthorizedContent roles={["super-admin", "admin"]}>
                          <button
                            onClick={() => {
                              setshowOtopns(null);
                              setshowRenamefile(docs);
                              setRenamefile(docs.file_name);
                            }}
                          >
                            Rename
                          </button>
                        </AuthorizedContent>
                        <button
                          onClick={() => {
                            setshowOtopns(null);
                            setshowDetails(docs);
                          }}
                        >
                          Details
                        </button>
                        <AuthorizedContent roles={["super-admin", "admin"]}>
                          <button
                            className="documents-content-blockleft-delete"
                            onClick={() => handleDelete(docs._id)}
                          >
                            Delete
                          </button>
                        </AuthorizedContent>
                      </div>
                    </OutsideAlerter>
                  ) : null}
                </div>
              </motion.div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default Documents;
