import React from "react";
import useAuth from "./useAuth";
import { useLocation, Navigate, Outlet } from "react-router-dom";

function RequireAdmin() {
  const { organisation } = useAuth();
  const location = useLocation();
  return organisation?._id ? <Outlet /> : <Navigate to="/organisations" state={{ from: location }} replace />;
}

export default RequireAdmin;
