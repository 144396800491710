// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAWXlR6zyIlc_HKmpBcEzVAv7-dbBEI-H0",
  authDomain: "newstorage-d1969.firebaseapp.com",
  projectId: "newstorage-d1969",
  storageBucket: "newstorage-d1969.appspot.com",
  messagingSenderId: "738755919670",
  appId: "1:738755919670:web:080f2524d38f9e7a73bd97",
  measurementId: "G-FJ4VB08ZE6",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
