import React from "react";
import "./mainloader.css";

function MainLoader() {
  return (
    <div className="mainloader-wrapper">
      <div className="dotted">
        <li></li>
        <li></li>
        <li></li>
      </div>
    </div>
  );
}

export default MainLoader;
