import React, { useState, useEffect } from "react";
import "./notifications.css";
import { ReactComponent as NotificationIcon } from "../../../assets/navbar/notification.svg";

import AuthorizedContent from "../../../shared/AuthorizedContent";
import FlipLoader from "../../../shared/fliploader/FlipLoader";
import { motion } from "framer-motion";
import axiosInstance from "../../../helpers/axiosInstance";
import useAuth from "../../../hooks/useAuth";
import OutsideAlerter from "../../../helpers/outsideAlerter";
import { ReactComponent as KebabIcon } from "../../../assets/kebab.svg";
import { toast } from "react-toastify";
import { ReactComponent as ArrowIcon } from "../../../assets/signin/right.svg";

function Notifications() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { organisation } = useAuth();
  const [header, setHeader] = useState(null);
  const [content, setContent] = useState(null);
  const [showoptions, setshowOptions] = useState(null);
  const [type, setType] = useState(1);

  useEffect(() => {
    getallNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getallNotifications = () => {
    axiosInstance
      .get(`/api/notification/${organisation._id}/getallnotifications`)
      .then((response) => {
        setLoading(false);
        setNotificationData(response.data.data);
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
      });
  };
  const handleSubmit = () => {
    setLoading(true);
    const data = {
      note_header: header,
      note_body: content,
      note_type: type,
    };
    axiosInstance
      .post(`/api/notification/${organisation._id}/addnotification`, data)
      .then((response) => {
        getallNotifications();
        setShowAddModal(false);
        toast("Notification added successfully.");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast("Unable to add notification!");
      });
  };
  const handleDelete = (id) => {
    setLoading(true);
    axiosInstance
      .delete(`/api/notification/${organisation._id}/deletenotification/${id}`)
      .then((response) => {
        getallNotifications();
        toast("Notification deleted successfully.");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        toast("Unable to delete notification!");
      });
  };

  const renderAddModal = () => {
    return (
      <motion.section
        className="notification-modal-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.2 }}
      >
        <header className="notification-modal-header">
          <ArrowIcon
            className="notification-modal-lefticon"
            onClick={() => setShowAddModal(false)}
          />
          Add Notification
        </header>

        <main className="notification-modal-body">
          <div className="notification-form">
            <label>Notification header</label>
            <input
              type="text"
              name="date"
              onChange={({ target }) => {
                setHeader(target.value);
              }}
              required={true}
            />
          </div>
          <div className="notification-form">
            <label>Notification Content</label>
            <input
              type="text"
              name="amount"
              onChange={({ target }) => {
                setContent(target.value);
              }}
              required={true}
            />
          </div>
          <div className="notification-form">
            <label>Transaction Type</label>
            <select
              onChange={({ target }) => {
                setType(parseInt(target.value));
              }}
            >
              <option value={null}></option>
              <option value={1}>Notification</option>
            </select>
          </div>
        </main>
        <footer className="notification-modal-footer">
          <button className="notification-addbtn" onClick={handleSubmit}>
            Submit
          </button>
        </footer>
      </motion.section>
    );
  };
  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " y";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " m";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " d";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hr";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " min";
    }
    return Math.floor(seconds) + " sc";
  }
  const renderImage = (ext) => {
    switch (ext) {
      case "1":
        return <NotificationIcon className="notification-docs-icons" />;
      default:
        return <NotificationIcon className="notification-docs-icons" />;
    }
  };
  return (
    <div className="notification-container">
      {showAddModal ? renderAddModal() : null}
      <div className="home-header">
        <div className="home-headerleft">
          <h3 className="home-heading">Notifications</h3>
        </div>
        <AuthorizedContent roles={["super-admin", "admin"]}>
          <button
            className="interest-addbtn"
            onClick={() => setShowAddModal(true)}
          >
            Add Notification
          </button>
        </AuthorizedContent>
      </div>
      <div className="notification-content">
        {loading ? (
          <div className="notification-loader">
            <FlipLoader />
          </div>
        ) : !notificationData?.length ? (
          <p>There are no Notifications.</p>
        ) : (
          notificationData?.map((note, idx) => {
            return (
              <motion.div
                className="notification-content-block"
                key={idx}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
              >
                {renderImage(note.note_type)}
                <div className="notification-content-blockright">
                  <label className="notification-content-blockheader">
                    {note.note_header}
                  </label>
                  <label className="notification-content-blockcontent">
                    {note.note_body}
                  </label>
                  <label className="notification-content-blocktime">
                    {timeSince(note.added_date)}
                  </label>
                </div>
                <AuthorizedContent roles={["super-admin", "admin"]}>
                  <div className="notification-content-blockleft">
                    <div
                      className="notification-content-kebabblock"
                      onClick={() => setshowOptions(note._id)}
                    >
                      <KebabIcon />
                    </div>
                    {showoptions === note._id ? (
                      <OutsideAlerter
                        resetAlert={() =>
                          setTimeout(() => {
                            setshowOptions(null);
                          }, 100)
                        }
                      >
                        <div className="notification-content-blockleft-menu">
                          <AuthorizedContent roles={["super-admin", "admin"]}>
                            <button
                              className="notification-content-blockleft-delete"
                              onClick={() => handleDelete(note._id)}
                            >
                              Delete
                            </button>
                          </AuthorizedContent>
                        </div>
                      </OutsideAlerter>
                    ) : null}
                  </div>
                </AuthorizedContent>
              </motion.div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default Notifications;
