import React from "react";
import "./home.css";
import useAuth from "../../../hooks/useAuth";
import Dashboard from "../dashboard/Dashboard";
import Members from "../members_container/Members";
import MemberProfile from "../members_container/memberDetails/memberprofile/MemberProfiles";
import SavingsContainer from "../members_container/memberDetails/savingscontainer/SavingsContainer";
import LoanContainer from "../members_container/memberDetails/loancontainer/LoanContainer";
import RepaymentContainer from "../members_container/memberDetails/repaymentcontainer/RepaymentContainer";
import { Route, Routes } from "react-router-dom";
import { Layout } from "../../../shared/Layout";
import ExploreContainer from "../explore_container/ExploreContainer";
import Notfound from "../../../shared/notfound/Notfound";
import Notifications from "../notifications/Notifications";
import Documents from "../documents/Documents";
import Vouchers from "../vouchers/Vouchers";
import Applyloans from "../applyloans/Applyloans";
import InterestRecord from "../interestrecord/InterestRecord";
// import Payments from "../payments/Payments";
import ChatRoom from "../chatroom/ChatRoom";
function Home() {
  const { auth, organisation } = useAuth();

  return (
    <>
      <Routes>
        <Route path="admin/*" element={<Layout />}>
          <Route
            path="home"
            element={
              <div className="home">
                <div className="home-wrapper">
                  <section className="home-content home-fillcontent">
                    <Dashboard organisation={organisation} />
                  </section>
                </div>
              </div>
            }
          ></Route>
          <Route path="members" element={<Members />}></Route>
          <Route
            path="profile"
            element={
              <div className="home">
                <div className="home-wrapper">
                  <div className="home-header">
                    <div className="home-headerleft">
                      <h3 className="home-heading">Profile</h3>
                    </div>
                  </div>
                  <section className="home-content">
                    <MemberProfile selectedMember={auth.user} page="profile" />
                  </section>
                </div>
              </div>
            }
          ></Route>

          <Route
            path="explore"
            element={
              <div className="home">
                <div className="home-wrapper">
                  <section className="home-content home-fillcontent">
                    <ExploreContainer selectedMember={auth.user} />
                  </section>
                </div>
              </div>
            }
          >
            {/* <Route index path="explore" element={<></>}></Route> */}
          </Route>
          <Route
            path="save"
            element={
              <div className="home">
                <div className="home-wrapper">
                  <section className="home-content home-fillcontent">
                    <SavingsContainer selectedMember={auth.user} />
                  </section>
                </div>
              </div>
            }
          ></Route>
          <Route
            path="docs"
            element={
              <div className="home">
                <div className="home-wrapper">
                  <section className="home-content home-fillcontent">
                    <Documents selectedMember={auth.user} />
                  </section>
                </div>
              </div>
            }
          ></Route>
          <Route
            path="loans"
            element={
              <div className="home">
                <div className="home-wrapper">
                  <section className="home-content home-fillcontent">
                    <LoanContainer selectedMember={auth.user} />{" "}
                  </section>
                </div>
              </div>
            }
          ></Route>
          <Route
            path="repayment"
            element={
              <div className="home">
                <div className="home-wrapper">
                  <section className="home-content home-fillcontent">
                    <RepaymentContainer selectedMember={auth.user} />
                  </section>
                </div>
              </div>
            }
          ></Route>
          <Route
            path="notifications"
            element={
              <div className="home">
                <div className="home-wrapper">
                  <section className="home-content home-fillcontent">
                    <Notifications selectedMember={auth.user} />
                  </section>
                </div>
              </div>
            }
          ></Route>
          <Route
            path="vouchers"
            element={
              <div className="home">
                <div className="home-wrapper">
                  <section className="home-content home-fillcontent">
                    <Vouchers selectedMember={auth.user} />
                  </section>
                </div>
              </div>
            }
          ></Route>
          <Route
            path="applyloans"
            element={
              <div className="home">
                <div className="home-wrapper">
                  <section className="home-content home-fillcontent">
                    <Applyloans selectedMember={auth.user} />
                  </section>
                </div>
              </div>
            }
          ></Route>
          <Route
            path="interestrecord"
            element={
              <div className="home">
                <div className="home-wrapper">
                  <section className="home-content home-fillcontent">
                    <InterestRecord selectedMember={auth.user} />
                  </section>
                </div>
              </div>
            }
          ></Route>
          <Route
            path="chatroom"
            element={
              <div className="home">
                <div className="home-wrapper">
                  <section className="home-content home-fillcontent">
                    <ChatRoom selectedMember={auth.user} />
                  </section>
                </div>
              </div>
            }
          ></Route>
        </Route>
        <Route path="*" element={<Notfound />}></Route>
      </Routes>
    </>
  );
}

export default Home;
