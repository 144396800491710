import React, { useEffect, useRef, useState } from "react";
import "./forgotpassword.css";
import { ReactComponent as MainLogo } from "../../assets/signin/Logo.svg";
import axiosInstance from "../../helpers/axiosInstance";
import Loading from "../../shared/loading/Loading";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

function ForgotPassword() {
  const [otpgenerated, setOtpgenerated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState("forgotpassword");
  const emailRef = useRef("");
  const otpRef = useRef("");
  const newpasswordRef = useRef("");
  const confirmpasswordRef = useRef("");
  const navigate = useNavigate();

  useEffect(() => {
    let email = localStorage.getItem("forgotpasswordemail");
    if (email) {
      setOtpgenerated(true);
    }
    return () => {
      localStorage.removeItem("forgotpasswordemail");
      localStorage.removeItem("forgotpasswordUserID");
    };
  }, []);

  function sendOTP(e) {
    setLoading(true);
    const Logindata = {
      email: emailRef.current.value,
    };
    e.preventDefault();
    axiosInstance
      .post("/api/user/generateotp", Logindata)
      .then((response) => {
        setLoading(false);
        localStorage.setItem("forgotpasswordemail", emailRef.current.value);
        // localStorage.setItem("forgotpasswordotp", response.data.accessToken);
        toast(`OTP sent to ${emailRef.current.value}`);
        setOtpgenerated(true);
        emailRef.current.value = "";
        // otpRef.current.value = "";
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to Generate OTP");
        console.log(error);
      });
  }
  function verifyOTP(e) {
    setLoading(true);
    const Logindata = {
      email: localStorage.getItem("forgotpasswordemail"),
      otp: otpRef.current.value,
    };
    e.preventDefault();
    axiosInstance
      .post("/api/user/validateotp", Logindata)
      .then((response) => {
        setLoading(false);
        localStorage.removeItem("forgotpasswordemail");
        // console.log(response.data);
        localStorage.setItem("forgotpasswordUserID", response.data.data._id);
        toast(`OTP verified!`);
        setOption("changepassword");
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to Generate OTP");
        console.log(error);
      });
  }

  function changePassword(e) {
    if (newpasswordRef.current.value === confirmpasswordRef.current.value) {
      setLoading(true);
      const Logindata = {
        password: newpasswordRef.current.value,
      };
      e.preventDefault();
      axiosInstance
        .post(
          `/api/user/updatepassword/${localStorage.getItem(
            "forgotpasswordUserID"
          )}`,
          Logindata
        )
        .then((response) => {
          setLoading(false);
          navigate("signin", { replace: true });
          toast(`Password changed Successfull`);
        })
        .catch((error) => {
          setLoading(false);
          toast("Unable to change password.");
          console.log(error);
        });
    } else {
      e.preventDefault();
      toast("Password doesn't match.");
    }
  }

  return (
    <div className="forgot-container">
      <MainLogo className="forgot-main-logo" />
      {option === "forgotpassword" ? (
        <div className="forgot-login-wrapper">
          <label>Forgot Password</label>
          {!otpgenerated ? (
            <>
              <div className="forgot-login-formsection">
                <input
                  autoFocus
                  tabIndex="1"
                  type="text"
                  ref={emailRef}
                  placeholder="Enter your registered email ID"
                  autoComplete="off"
                  name="email"
                />
              </div>
              <div className="forgot-forgotpassword-right">
                <label
                  onClick={() => {
                    navigate("/signin", { replace: true });
                  }}
                >
                  Go to SignIn
                </label>
              </div>
              <button
                className="forgot-login-btn"
                disabled={loading}
                onClick={sendOTP}
              >
                {loading ? <Loading /> : "Genreate OTP"}
              </button>
            </>
          ) : (
            <>
              <div className="forgot-login-formsection">
                <input
                  tabIndex="2"
                  type="password"
                  ref={otpRef}
                  placeholder="OTP"
                  autoComplete="off"
                  name="otp"
                />
              </div>
              <div className="forgot-forgotpassword-right">
                <label
                  onClick={() => {
                    navigate("/signin", { replace: true });
                  }}
                >
                  Go to SignIn
                </label>
              </div>
              <button
                className="forgot-login-btn"
                disabled={loading}
                onClick={verifyOTP}
              >
                {loading ? <Loading /> : "Verify OTP"}
              </button>
            </>
          )}
        </div>
      ) : (
        <form className="forgot-login-wrapper" onSubmit={changePassword}>
          <label>Change Password</label>
          <div className="forgot-login-formsection">
            <input
              autoFocus
              tabIndex="1"
              type="password"
              ref={newpasswordRef}
              placeholder="Enter new password"
              autoComplete="off"
              name="confirmpassword"
            />
            <input
              tabIndex="2"
              type="password"
              ref={confirmpasswordRef}
              placeholder="Confirm password"
              autoComplete="off"
              name="confirmpassword"
            />
          </div>
          <div className="forgot-forgotpassword-right">
            <label
              onClick={() => {
                navigate("/signin", { replace: true });
              }}
            >
              Go to SignIn
            </label>
          </div>

          <button className="forgot-login-btn" disabled={loading}>
            {loading ? <Loading /> : "Sign In"}
          </button>
        </form>
      )}
    </div>
  );
}

export default ForgotPassword;
