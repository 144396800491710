import React, { useState } from "react";
import "./addmember.css";
import axiosInstance from "../../../../helpers/axiosInstance";
import useAuth from "../../../../hooks/useAuth";
import { toast } from "react-toastify";
import Loading from "../../../../shared/loading/Loading";
import AuthorizedContent from "../../../../shared/AuthorizedContent";

function AddMember(props) {
  const { auth, organisation } = useAuth();
  const [incomplete, setIncomlete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMember, setSelectedMember] = useState();
  const handleOnChange = ({ target }) => {
    const selectedMemberObj = {
      ...selectedMember,
      [target.name]: target.value,
    };
    setSelectedMember(selectedMemberObj);
  };
  const handleSubmit = () => {
    setLoading(true);
    if (checkRequired() && validatePhoneNo() && validateEmail()) {
      const selectedMemberObj = {
        id: selectedMember._id,
        email: selectedMember?.email.toString().toLowerCase(),
        ...selectedMember,
      };
      delete selectedMemberObj._id;
      axiosInstance
        .post(`/api/member/${organisation._id}/create`, selectedMemberObj)
        .then((response) => {
          setLoading(false);
          toast("Member added successfully");
          props.updateParent();
          console.log(response.data);
        })
        .catch((error) => {
          setLoading(false);
          toast("Unable to add member. Try again!");
          console.log(error);
        });
    } else {
      setLoading(false);
      setIncomlete(true);
      toast("Fill up all required fields. Try again!");
    }
  };

  const checkRequired = () => {
    return (
      selectedMember?.member_id &&
      selectedMember?.first_name &&
      selectedMember?.last_name &&
      selectedMember?.gender &&
      selectedMember?.email &&
      selectedMember?.role &&
      selectedMember?.phone_no
    );
  };
  const validatePhoneNo = () => {
    return selectedMember?.phone_no?.match(/^\d{10}$/);
  };
  const validateEmail = () => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      selectedMember?.email
    );
  };
  return (
    <>
      <section className="addmember">
        <div className="addmember-form">
          <label htmlFor="first_name" className="required">
            Member Id
          </label>
          <input
            type="text"
            className={
              incomplete && !selectedMember?.member_id ? "input-incomplete" : ""
            }
            name="member_id"
            onChange={handleOnChange}
            value={selectedMember?.member_id}
            required
          />
        </div>
        <div className="addmember-form">
          <label htmlFor="first_name" className="required">
            First Name
          </label>
          <input
            type="text"
            className={
              incomplete && !selectedMember?.first_name
                ? "input-incomplete"
                : ""
            }
            name="first_name"
            onChange={handleOnChange}
            value={selectedMember?.first_name}
            required
          />
        </div>
        <div className="addmember-form">
          <label htmlFor="last_name" className="required">
            Last Name
          </label>
          <input
            type="text"
            className={
              incomplete && !selectedMember?.last_name ? "input-incomplete" : ""
            }
            name="last_name"
            onChange={handleOnChange}
            value={selectedMember?.last_name}
          />
        </div>
        <div className="addmember-form">
          <label htmlFor="role" className="required">
            Role
          </label>
          <select
            name="role"
            className={
              incomplete && !selectedMember?.role ? "input-incomplete" : ""
            }
            onChange={handleOnChange}
            value={selectedMember?.role}
            disabled={auth?.user?.role === "member"}
            required
          >
            <option value={null}> </option>
            <AuthorizedContent roles={["super-admin", "admin"]}>
              <option key={"president"} value={"president"}>
                President
              </option>
            </AuthorizedContent>
            <AuthorizedContent roles={["super-admin", "president", "admin"]}>
              <option key={"admin"} value={"admin"}>
                Admin
              </option>
            </AuthorizedContent>
            <AuthorizedContent
              roles={["super-admin", "president", "admin", "secretary"]}
            >
              <option key={"secretary"} value={"secretary"}>
                Secretary
              </option>
            </AuthorizedContent>
            <AuthorizedContent
              roles={["super-admin", "admin", "president", "secretary"]}
            >
              <option key={"member"} value={"member"}>
                Member
              </option>
            </AuthorizedContent>
          </select>
        </div>
        <div className="addmember-form">
          <label htmlFor="gender" className="required">
            Gender
          </label>
          <select
            name="gender"
            className={
              incomplete && !selectedMember?.gender ? "input-incomplete" : ""
            }
            onChange={handleOnChange}
            value={selectedMember?.gender}
            required
          >
            <option value={null}> </option>
            <option value={"male"}>Male</option>
            <option value={"female"}>Female</option>
          </select>
        </div>
        <div className="addmember-form">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            name="address"
            onChange={handleOnChange}
            value={selectedMember?.address}
          />
        </div>
        <div className="addmember-form">
          <label htmlFor="state">State</label>
          <input
            type="text"
            name="state"
            onChange={handleOnChange}
            value={selectedMember?.state}
          />
        </div>
        <div className="addmember-form">
          <label htmlFor="city">City</label>
          <input
            type="text"
            name="city"
            onChange={handleOnChange}
            value={selectedMember?.city}
          />
        </div>
        <div className="addmember-form">
          <label htmlFor="nationality">Nationality</label>
          <input
            type="text"
            name="nationality"
            onChange={handleOnChange}
            value={selectedMember?.nationality}
          />
        </div>
        <div className="addmember-form">
          <label htmlFor="pin_code">Pin Code</label>
          <input
            type="text"
            name="pin_code"
            onChange={handleOnChange}
            value={selectedMember?.pin_code}
          />
        </div>
        <div className="addmember-form">
          <label htmlFor="age">Age</label>
          <input
            type="text"
            name="age"
            onChange={handleOnChange}
            value={selectedMember?.age}
          />
        </div>
        <div className="addmember-form">
          <label htmlFor="email" className="required">
            Email
          </label>
          <input
            type="text"
            className={incomplete && !validateEmail() ? "input-incomplete" : ""}
            name="email"
            onChange={handleOnChange}
            value={selectedMember?.email}
            disabled={auth?.user?.role === "member"}
            required
          />
        </div>
        <div className="addmember-form">
          <label htmlFor="phone_no" className="required">
            Phone No
          </label>
          <input
            className={
              incomplete && !validatePhoneNo() ? "input-incomplete" : ""
            }
            type="text"
            name="phone_no"
            onChange={handleOnChange}
            value={selectedMember?.phone_no}
          />
        </div>
        <div className="addmember-form">
          <label htmlFor="age">UPI Id</label>
          <input
            type="text"
            name="upi_id"
            onChange={handleOnChange}
            value={selectedMember?.upi_id}
          />
        </div>
      </section>
      <div className="addmember-footer">
        <button className="addmember-formsubmit" onClick={handleSubmit}>
          {loading ? <Loading /> : "Submit"}
        </button>
      </div>
    </>
  );
}

export default AddMember;
