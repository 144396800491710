import React, { useEffect, useState } from "react";
import "./repaymentcontainer.css";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../helpers/axiosInstance";
import AuthorizedContent from "../../../../../shared/AuthorizedContent";
import FlipLoader from "../../../../../shared/fliploader/FlipLoader";
import { ReactComponent as ArrowIcon } from "../../../../../assets/signin/right.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/signin/delete.svg";
import { ReactComponent as ProfileIcon } from "../../../../../assets/navbar/profile.svg";
import { ReactComponent as FilterIcon } from "../../../../../assets/filter.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/plus.svg";
import useAuth from "../../../../../hooks/useAuth";
import { filterLoanID } from "../../../../../helpers/filterLoanId";
import { AnimatePresence, motion } from "framer-motion";

function RepaymentContainer(props) {
  const [transactionList, setTransactionList] = useState([]);
  const [transactionMetadata, setTransactionMetadata] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [loansList, setLoansList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState(0);
  const [term, setTerm] = useState(0);
  const [loanID, setLoanID] = useState(null);
  const [interest, setInterest] = useState(0);
  const [comment, setcomment] = useState(null);
  const { organisation, auth } = useAuth();

  const [filtermemberid, setFiltermemberid] = useState("all");
  const [showFilterModal, setshowFilterModal] = useState(false);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialData = () => {
    if (auth.user.role === "member" || props.parent === "details") {
      getTransactionDetails();
      getLoanDetails();
    } else {
      getAllTransactionDetails();
      getAllMembersList();
    }
  };

  const getTransactionDetails = () => {
    axiosInstance
      .get(
        `/api/repayment/${organisation._id}/getbyuser/${props.selectedMember?._id}`
      )
      .then((response) => {
        setTransactionList(response.data.data);
        setTransactionMetadata(response.data.metadata);
        setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };
  const getAllTransactionDetails = () => {
    axiosInstance
      .get(
        `/api/repayment/${organisation._id}/getallrepayment?member_id=${filtermemberid}`
      )
      .then((response) => {
        setTransactionList(response.data.data);
        setTransactionMetadata(response.data.metadata);
        setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };
  const getAllMembersList = () => {
    axiosInstance
      .get(`/api/member/${organisation._id}/getallminimalmembers`)
      .then((response) => {
        setMembersList(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get members details. Try again!");
        console.log(error);
      });
  };
  const getLoanDetails = (id = props.selectedMember?._id) => {
    axiosInstance
      .get(`/api/loans/${organisation._id}/filteractiveloans/${id}`)
      .then((response) => {
        setLoansList(response.data);
        // setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        // setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };
  const handleDelete = (id) => {
    setLoading(true);
    axiosInstance
      .delete(`/api/repayment/${organisation._id}/deleterepayment/${id}`)
      .then((response) => {
        setLoading(false);
        toast("Member loan repayment transaction deleted successfully");
        getInitialData();
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };

  const checkRequiredFields = () => {
    return (
      date &&
      typeof amount === "number" &&
      loanID &&
      typeof term === "number" &&
      typeof interest === "number"
    );
  };

  const handleSubmit = () => {
    console.log(checkRequiredFields());
    if (!checkRequiredFields()) {
      toast("Please fill all the required fields.");
    } else {
      setLoading(true);
      setShowModal(false);
      axiosInstance
        .post(`/api/repayment/${organisation._id}/create`, {
          amount: parseInt(amount),
          date: Date.parse(date),
          term,
          interest,
          loanId: loanID,
          comment: comment,
          userId:
            (auth.user.role === "admin" || auth.user.role === "super-admin") &&
            props.parent !== "details"
              ? selectedMember
              : props.selectedMember?._id,
        })
        .then((response) => {
          toast("Loan repayment transaction added successfully");
          getInitialData();
          console.log(response.data);
        })
        .catch((error) => {
          setLoading(false);
          toast("Unable to add Loan repayment transaction details. Try again!");
          console.log(error);
        });
    }
  };

  const renderDetailModal = () => {
    let date = new Date(selectedDetail.date).toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    return (
      <section className="repaymentcontainer-detailmodal-container">
        <div className="repaymentcontainer-detailmodal-content">
          <header className="repaymentcontainer-detailmodal-header">
            <ArrowIcon
              className="repaymentcontainer-modal-lefticon"
              onClick={() => setSelectedDetail(null)}
            />
            Loan Repayment Details
          </header>
          <main className="repaymentcontainer-detailmodal-body">
            <div className="repaymentcontainer-detailmodal-form">
              <label>Repayment Id</label>
              <p>{filterLoanID(selectedDetail._id, selectedDetail.date)}</p>
            </div>
            <div className="repaymentcontainer-detailmodal-form">
              <label>Loan Id</label>
              <p>
                {filterLoanID(
                  selectedDetail.loanId,
                  selectedDetail?.loan?.date || selectedDetail.date
                )}
              </p>
            </div>
            <div className="repaymentcontainer-detailmodal-form">
              <label>Amount</label>
              <p>{selectedDetail.amount}</p>
            </div>

            <div className="repaymentcontainer-detailmodal-form">
              <label>Date</label>
              <p>{date}</p>
            </div>
            <div className="repaymentcontainer-detailmodal-form">
              <label>Term</label>
              <p>
                {selectedDetail.term}/{selectedDetail.total_term}
              </p>
            </div>
            <div className="repaymentcontainer-detailmodal-form">
              <label>Interest</label>
              <p>{selectedDetail.interest}</p>
            </div>
            <div className="repaymentcontainer-detailmodal-form">
              <label>Total Amount</label>
              <p>{selectedDetail.amount + selectedDetail.interest}</p>
            </div>
            <div className="repaymentcontainer-detailmodal-form">
              <label>Remarks</label>
              <p>{selectedDetail.comment}</p>
            </div>
          </main>
        </div>
      </section>
    );
  };

  const renderModal = () => {
    return (
      <motion.section
        className="repaymentcontainer-modal-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.2 }}
      >
        <header className="repaymentcontainer-modal-header">
          <ArrowIcon
            className="repaymentcontainer-modal-lefticon"
            onClick={() => setShowModal(false)}
          />
          Add Loan Repayment
        </header>
        <main className="repaymentcontainer-modal-body">
          {props.parent !== "details" ? (
            <div className="repaymentcontainer-form">
              <label>Member</label>
              <select
                name="date"
                onChange={({ target }) => {
                  console.log(target.value);
                  setSelectedMember(target.value);
                  getLoanDetails(target.value);
                }}
                value={selectedMember}
                required={true}
              >
                <option value={null}>Select </option>
                {membersList.map((member) => {
                  return (
                    <option value={member._id} key={member._id}>
                      {" "}
                      {member.full_name}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}
          <div className="repaymentcontainer-form">
            <label>Date</label>
            <input
              type="date"
              name="date"
              onChange={({ target }) => {
                setDate(target.value);
              }}
              placeholder={date}
              value={date}
              required={true}
            />
          </div>
          <div className="repaymentcontainer-form">
            <label>Loan ID</label>
            <select
              name="date"
              onChange={({ target }) => {
                setLoanID(target.value);
              }}
              disabled={!loansList.length}
              value={loanID}
              required={true}
            >
              <option value={null}>Select </option>
              {loansList.map((loans) => {
                return (
                  <option value={loans._id} key={loans._id}>
                    {" "}
                    {filterLoanID(loans._id, loans.date)}{" "}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="repaymentcontainer-form">
            <label>Amount</label>
            <input
              type="text"
              name="amount"
              onChange={({ target }) => {
                setAmount(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="repaymentcontainer-form">
            <label>Term</label>
            <input
              type="text"
              name="term"
              onChange={({ target }) => {
                setTerm(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="repaymentcontainer-form">
            <label>Interest Amount</label>
            <input
              type="text"
              name="interest"
              onChange={({ target }) => {
                setInterest(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="repaymentcontainer-form">
            <label>Remarks</label>
            <input
              type="text"
              name="interest"
              onChange={({ target }) => {
                setcomment(target.value);
              }}
              required={true}
            />
          </div>
        </main>
        <footer className="repaymentcontainer-modal-footer">
          {" "}
          <button className="repaymentcontainer-addbtn" onClick={handleSubmit}>
            Submit
          </button>
        </footer>
      </motion.section>
    );
  };

  const rendrFilterModal = () => {
    return (
      <section className="repaymentcontainer-detailmodal-container">
        <div className="repaymentcontainer-detailmodal-content">
          <header className="repaymentcontainer-detailmodal-header">
            <ArrowIcon
              className="repaymentcontainer-modal-lefticon"
              onClick={() => {
                setshowFilterModal(false);
              }}
            />
            Filters
          </header>

          <main className="repaymentcontainer-detailmodal-body">
            <div className="repaymentcontainer-form">
              <label>Users</label>
              <select
                value={filtermemberid}
                onChange={({ target }) => {
                  setFiltermemberid(target.value);
                }}
              >
                <option value={"all"}>All </option>
                {membersList.map((member) => {
                  return (
                    <option value={member._id} key={member._id}>
                      {" "}
                      {member.full_name}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
          </main>
          <footer className="repaymentcontainer-filter-footer">
            <button
              className="repaymentcontainer-addbtn repaymentcontainer-filter-footerbtn"
              onClick={() => {
                setFiltermemberid("all");
              }}
            >
              Clear
            </button>
            <button
              className="repaymentcontainer-addbtn repaymentcontainer-filter-footerbtn"
              onClick={() => {
                setLoading(true);
                getAllTransactionDetails();
                setshowFilterModal(false);
              }}
            >
              Apply
            </button>
          </footer>
        </div>
      </section>
    );
  };

  return (
    <>
      <AnimatePresence>{showModal ? renderModal() : null}</AnimatePresence>
      {selectedDetail ? renderDetailModal() : null}
      {showFilterModal ? rendrFilterModal() : null}
      <div className="repaymentcontainer-header">
        {props.parent !== "details" ? (
          <h3 className="repaymentcontainer-heading">Repayment</h3>
        ) : null}
        <AuthorizedContent roles={["super-admin", "admin"]}>
          <button
            className="repaymentcontainer-filterbtn"
            onClick={() => setshowFilterModal(true)}
          >
            <FilterIcon />
          </button>
        </AuthorizedContent>
        <AuthorizedContent roles={["super-admin", "admin"]}>
          <button
            className="repaymentcontainer-addiconbtn"
            onClick={() => setShowModal(true)}
          >
            <PlusIcon />
          </button>
        </AuthorizedContent>
      </div>
      <div className="repaymentcontainer-wrapper">
        {loading ? (
          <div className="repaymentcontainer-loader">
            <FlipLoader />
          </div>
        ) : !transactionList?.length ? (
          <p>There are no repayment transactions.</p>
        ) : (
          <div className="repaymentcontainer-listwrapper">
            <motion.header
              className="repaymentcontainer-listwrapper-header"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.1 }}
            >
              <label className="repaymentcontainer-listwrapper-headerleft">
                Entries : <span>{transactionMetadata.count}</span>
              </label>
              <label className="repaymentcontainer-listwrapper-headerright">
                Pending Loan Amount :{" "}
                <span>₹ {transactionMetadata.pending_loan}</span>{" "}
              </label>
            </motion.header>
            {transactionList?.map((transaction, idx) => {
              let date = new Date(transaction.date).toLocaleDateString(
                "en-us",
                {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              );
              return (
                <motion.div
                  className="repaymentcontainer-list"
                  key={idx}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="repaymentcontainer-listleft">
                    <p className="repaymentcontainer-listleft-date">{date}</p>
                    {props.parent !== "details" &&
                    auth.user.role !== "member" ? (
                      <p className=" repaymentcontainer-listleft-user">
                        <ProfileIcon /> {transaction.member.first_name}{" "}
                        {transaction.member.last_name}
                      </p>
                    ) : null}
                    <p
                      className="repaymentcontainer-listleft-id"
                      onClick={() => {
                        setSelectedDetail(transaction);
                      }}
                    >
                      {filterLoanID(transaction._id, transaction.date)}
                    </p>
                  </div>
                  <label
                    className={
                      auth.user.role === "super-admin" ||
                      auth.user.role === "admin"
                        ? "repaymentcontainer-listrighttotal"
                        : "repaymentcontainer-listrighttotal repaymentcontainer-listrightmember"
                    }
                  >
                    ₹ {transaction.amount + transaction.interest}
                  </label>

                  <AuthorizedContent roles={["super-admin", "admin"]}>
                    <div
                      className="repaymentcontainer-listrightdelete"
                      onClick={() => handleDelete(transaction._id)}
                    >
                      <DeleteIcon />
                    </div>
                  </AuthorizedContent>
                </motion.div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default RepaymentContainer;
