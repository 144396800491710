import React, { useEffect, useState } from "react";
import "./interestrecord.css";
import AuthorizedContent from "../../../shared/AuthorizedContent";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import axiosInstance from "../../../helpers/axiosInstance";
import { ReactComponent as ArrowIcon } from "../../../assets/signin/right.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/signin/delete.svg";
import { filterTransationId } from "../../../helpers/filterTransationId";
import { ReactComponent as TransactionArrowIcon } from "../../../assets/transactions/arrow.svg";
import FlipLoader from "../../../shared/fliploader/FlipLoader";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as FilterIcon } from "../../../assets/filter.svg";
import { ReactComponent as PlusIcon } from "../../../assets/plus.svg";

function InterestRecord() {
  const [recordList, setRecordList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { organisation, auth } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [showdetailModal, setshowdetailModal] = useState(null);
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState(0);
  const [comment, setComment] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [type, setType] = useState("LOAN");
  const [showFilterModal, setshowFilterModal] = useState(false);
  let startyear = 2019;
  let endyear = new Date().getFullYear();
  let years = [];

  for (let i = startyear; i <= endyear; i++) {
    years.push({ year: i, label: `April ${i} - March ${i + 1}` });
  }
  const [filtertype, setFiltertype] = useState("all");
  const [selectedYearLabel, setSelectedYearLabel] = useState(
    years[years.length - 1].label
  );
  const [filterYearLabel, setFilterYearLabel] = useState(
    years[years.length - 1].label
  );
  const [startYear, setStartYear] = useState(
    Date.parse(
      new Date(
        new Date(
          new Date(new Date(new Date().setHours(0, 0, 0)).setDate(1)).setMonth(
            3
          )
        ).setFullYear(
          new Date().getMonth() < 2
            ? new Date().getFullYear() - 1
            : new Date().getFullYear()
        )
      )
    )
  );
  const [endYear, setEndYear] = useState(
    Date.parse(
      new Date(
        new Date(
          new Date(
            new Date(new Date().setHours(23, 59, 59)).setMonth(2)
          ).setDate(31)
        ).setFullYear(
          new Date().getMonth() > 2
            ? new Date().getFullYear() + 1
            : new Date().getFullYear()
        )
      )
    )
  );

  useEffect(() => {
    getAllRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllRecords = () => {
    axiosInstance
      .get(
        `/api/interest/${organisation._id}/getallinterest?type=${filtertype}&startdate=${startYear}&enddate=${endYear}`
      )
      .then((response) => {
        setRecordList(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get interest records details. Try again!");
        console.log(error);
      });
  };

  const checkRequiredFields = () => {
    return date && typeof amount === "number" && amount !== 0 && type;
  };

  const addRecords = () => {
    if (!checkRequiredFields()) {
      toast("Please fill all the required fields");
    } else {
      const data = {
        date: Date.parse(date),
        amount: amount,
        comment: comment,
        type: type,
      };
      axiosInstance
        .post(`/api/interest/${organisation._id}/addinterest`, data)
        .then((response) => {
          getAllRecords();
          console.log(response.data);
          setShowModal(false);
          toast(" Interest record added successfully.");
        })
        .catch((error) => {
          setLoading(false);
          toast("Unable to add interest records details. Try again!");
          console.log(error);
        });
    }
  };

  const handleDelete = (id) => {
    axiosInstance
      .delete(`/api/interest/${organisation._id}/deleteinterest/${id}`)
      .then((response) => {
        getAllRecords();
        // setRecordList(response.data.data);
        // setLoading(false);
        console.log(response.data);
        toast(" Interest record removed successfully.");
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to delete interest records details. Try again!");
        console.log(error);
      });
  };

  const renderDetailModal = () => {
    let date = new Date(showdetailModal.date).toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return (
      <section className="interest-detailmodal-container">
        <div className="interest-detailmodal-content">
          <header className="interest-detailmodal-header">
            <ArrowIcon
              className="interest-modal-lefticon"
              onClick={() => setshowdetailModal(null)}
            />
            Interest Record Details
          </header>
          <main className="interest-detailmodal-body">
            <div className="interest-detailmodal-form">
              <label>Interest Record Id</label>
              <p>
                {filterTransationId(
                  showdetailModal.type,
                  showdetailModal.date,
                  showdetailModal._id
                )}
              </p>
            </div>
            <div className="interest-detailmodal-form">
              <label>Amount</label>
              <p>{showdetailModal.amount}</p>
            </div>
            <div className="interest-detailmodal-form">
              <label>Date</label>
              <p>{date}</p>
            </div>
            <div className="interest-detailmodal-form">
              <label>Interest Type</label>
              <p>{showdetailModal.type}</p>
            </div>

            <div className="interest-detailmodal-form">
              <label>Remarks</label>
              <p>{showdetailModal.comment || "-"}</p>
            </div>
          </main>
        </div>
      </section>
    );
  };

  const renderModal = () => {
    return (
      <motion.section
        className="interest-modal-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.2 }}
      >
        <header className="interest-modal-header">
          <ArrowIcon
            className="interest-modal-lefticon"
            onClick={() => setShowModal(false)}
          />
          Add Interet Record
        </header>

        <main className="interest-modal-body">
          <div className="interest-form">
            <label>Date</label>
            <input
              type="date"
              name="date"
              onChange={({ target }) => {
                setDate(target.value);
              }}
              placeholder={date}
              value={date}
              required={true}
            />
          </div>
          <div className="interest-form">
            <label>Amount</label>
            <input
              type="text"
              name="amount"
              onChange={({ target }) => {
                setAmount(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="interest-form">
            <label>Transaction Type</label>
            <select
              onChange={({ target }) => {
                setType(target.value);
              }}
            >
              <option value={null}></option>

              <option value="LOAN">LOAN</option>
              <option value="BANK_INTEREST">BANK INTEREST</option>
            </select>
          </div>

          <div className="interest-form">
            <label>Remarks</label>
            <input
              type="text"
              name="amount"
              value={comment}
              onChange={({ target }) => {
                setComment(target.value);
              }}
              required={true}
            />
          </div>
        </main>
        <footer className="interest-modal-footer">
          {" "}
          <button className="interest-addbtn" onClick={addRecords}>
            Submit
          </button>
        </footer>
      </motion.section>
    );
  };

  const rendrFilterModal = (filterYear) => {
    return (
      <section className="interest-detailmodal-container">
        <div className="interest-detailmodal-content">
          <header className="interest-detailmodal-header">
            <ArrowIcon
              className="interest-modal-lefticon"
              onClick={() => {
                setshowFilterModal(false);
              }}
            />
            Filters
          </header>

          <main className="interest-detailmodal-body">
            <div className="interest-form">
              <label>Transaction Type</label>
              <select
                value={filtertype}
                onChange={({ target }) => {
                  setFiltertype(target.value);
                }}
              >
                <option value={"all"}>All</option>
                <option value="LOAN">LOAN</option>
                <option value="BANK_INTEREST">BANK INTEREST</option>
              </select>
            </div>
            <div className="interest-form">
              <label>Financial year</label>

              <select
                // value={filterYear}

                placeholder={filterYearLabel}
                onChange={({ target }) => {
                  setFilterYearLabel(JSON.parse(target.value).label);
                  setStartYear(JSON.parse(target.value).startdate);
                  setEndYear(JSON.parse(target.value).enddate);
                }}
              >
                <option value={null}></option>
                {years.reverse().map((data) => {
                  return (
                    <option
                      value={JSON.stringify({
                        label: data.label,
                        startdate: Date.parse(
                          new Date(
                            new Date(
                              new Date(
                                new Date(new Date().setHours(0, 0, 0)).setDate(
                                  1
                                )
                              ).setMonth(3)
                            ).setFullYear(data.year)
                          )
                        ),
                        enddate: Date.parse(
                          new Date(
                            new Date(
                              new Date(
                                new Date(
                                  new Date().setHours(23, 59, 59)
                                ).setMonth(2)
                              ).setDate(31)
                            ).setFullYear(data.year + 1)
                          )
                        ),
                      })}
                    >
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </main>
          <footer className="interest-filter-footer">
            <button
              className="interest-addbtn interest-filter-footerbtn"
              onClick={() => {
                setFiltertype("all");
              }}
            >
              Clear
            </button>
            <button
              className="interest-addbtn interest-filter-footerbtn"
              onClick={() => {
                setLoading(true);
                setshowFilterModal(false);
                setSelectedYearLabel(filterYear);
                getAllRecords();
              }}
            >
              Apply
            </button>
          </footer>
        </div>
      </section>
    );
  };

  React.useMemo(() => {
    let total_amount = 0;

    recordList.forEach((record) => {
      total_amount = total_amount + record.amount;
    });
    setTotalAmount(total_amount);
  }, [recordList]);

  return (
    <div className="interest-container">
      <AnimatePresence>{showModal ? renderModal() : null}</AnimatePresence>
      {showdetailModal ? renderDetailModal() : null}
      {showFilterModal ? rendrFilterModal(filterYearLabel) : null}
      <div className="home-header">
        <div className="home-headerleft">
          <h3 className="home-heading">Interest Records</h3>
        </div>
        <button
          className="interest-filterbtn"
          onClick={() => setshowFilterModal(true)}
        >
          <FilterIcon />
        </button>
        <AuthorizedContent roles={["super-admin", "admin"]}>
          <button
            className="interest-addiconbtn"
            onClick={() => setShowModal(true)}
          >
            <PlusIcon />
          </button>
        </AuthorizedContent>
      </div>
      <div className="interest-content">
        {loading ? (
          <div className="interest-loader">
            <FlipLoader />
          </div>
        ) : !recordList?.length ? (
          <p>There are no Interest records.</p>
        ) : (
          <>
            <motion.header
              className="savingscontainer-listwrapper-header"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.1 }}
            >
              <label className="savingscontainer-listwrapper-headerleft">
                {selectedYearLabel}
                {/* Entries : <span>{recordList.length}</span> */}
              </label>
              <label className="savingscontainer-listwrapper-headerright">
                Total : <span>₹ {totalAmount}</span>{" "}
              </label>
            </motion.header>
            {recordList?.map((transaction) => {
              let date = new Date(transaction.date).toLocaleDateString(
                "en-US",
                {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              );
              return (
                <>
                  <motion.div
                    className="interest-list"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <div className="interest-listleft">
                      <p className="interest-listleft-date">{date}</p>

                      <p
                        className="interest-listleft-id"
                        onClick={() => setshowdetailModal(transaction)}
                      >
                        {filterTransationId(
                          transaction.type,
                          transaction.date,
                          transaction._id
                        )}
                      </p>
                    </div>
                    <label
                      className={
                        auth.user.role === "super-admin" ||
                        auth.user.role === "admin"
                          ? "interest-listrighttotal"
                          : "interest-listrighttotal interest-listrightmember"
                      }
                    >
                      <label
                        className={
                          // transaction.type === "credit"
                          //   ? "interest-listright-amountcredit"
                          //   :
                          "interest-listright-amountcredit"
                        }
                      >
                        <TransactionArrowIcon
                          className={
                            //   transaction.type === "credit"
                            // ? "interest-crediticon"
                            // :
                            "interest-crediticon"
                          }
                        />{" "}
                        ₹ {transaction.amount}
                      </label>
                      {/* <label className="interest-listright-closeamount">
                      <span>closing balance : </span>₹{transaction.total_amount}
                    </label> */}
                    </label>

                    <AuthorizedContent roles={["super-admin", "admin"]}>
                      <div
                        className="interest-listrightdelete"
                        onClick={() => handleDelete(transaction._id)}
                      >
                        <DeleteIcon />
                      </div>
                    </AuthorizedContent>
                  </motion.div>
                </>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default InterestRecord;
