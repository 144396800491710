import React, { useState } from "react";
import "./memberdetailcontainer.css";
import MemberProfile from "../memberprofile/MemberProfiles";
import SavingsContainer from "../savingscontainer/SavingsContainer";
import LoanContainer from "../loancontainer/LoanContainer";
import RepaymentContainer from "../repaymentcontainer/RepaymentContainer";

function MemberDetailContainer(props) {
  const [option, setOption] = useState("profile");

  const renderSectionBody = () => {
    switch (option) {
      case "profile":
        return <MemberProfile parent="details" selectedMember={props.selectedMember} updateParent={props.updateParent} goBack={props.goBack} />;
      case "savings":
        return <SavingsContainer parent="details" selectedMember={props.selectedMember} updateParent={props.updateParent} goBack={props.goBack} />;
        case "loans":
          return <LoanContainer parent="details" selectedMember={props.selectedMember} updateParent={props.updateParent} goBack={props.goBack} />;
          case "repayment":
        return <RepaymentContainer parent="details" selectedMember={props.selectedMember} updateParent={props.updateParent} goBack={props.goBack} />;
        default : return null
    }
  };

  return (
    <section className="membercontainer-wrapper">
      <div className="membercontainer-tabs">
        <button className={option === "profile" ? "membercontainer-tabbutton membercontainer-tabbuttonactive" : "membercontainer-tabbutton"} onClick={() => setOption("profile")}>
          Profile
        </button>
        <button className={option === "savings" ? "membercontainer-tabbutton membercontainer-tabbuttonactive" : "membercontainer-tabbutton"} onClick={() => setOption("savings")}>
          Savings
        </button>
        <button className={option === "loans" ? "membercontainer-tabbutton membercontainer-tabbuttonactive" : "membercontainer-tabbutton"} onClick={() => setOption("loans")}>
          Loans
        </button>
        <button className={option === "repayment" ? "membercontainer-tabbutton membercontainer-tabbuttonactive" : "membercontainer-tabbutton"} onClick={() => setOption("repayment")}>
          Repayment
        </button>
        {/* <button>Loan</button> */}
      </div>
      <section className="membercontainer-body">{renderSectionBody()}</section>
    </section>
  );
}

export default MemberDetailContainer;
