import React, { useEffect, useState } from "react";
import "./savingscontainer.css";
import axiosInstance from "../../../../../helpers/axiosInstance";
import { baseUrl } from "../../../../../helpers/config";
import FlipLoader from "../../../../../shared/fliploader/FlipLoader";
import { toast } from "react-toastify";
import AuthorizedContent from "../../../../../shared/AuthorizedContent";
import { ReactComponent as ArrowIcon } from "../../../../../assets/signin/right.svg";
import { ReactComponent as FilterIcon } from "../../../../../assets/filter.svg";
import { ReactComponent as ExportIcon } from "../../../../../assets/export.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/plus.svg";
import { ReactComponent as ProfileIcon } from "../../../../../assets/navbar/profile.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/signin/delete.svg";
import { ReactComponent as TransactionArrowIcon } from "../../../../../assets/transactions/arrow.svg";
import useAuth from "../../../../../hooks/useAuth";
import { filterTransationId } from "../../../../../helpers/filterTransationId";
import { AnimatePresence, motion } from "framer-motion";

function SavingsContainer(props) {
  const [transactionList, setTransactionList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [transactionMetadata, setTransactionMetadata] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showdetailModal, setshowdetailModal] = useState(null);
  const [showFilterModal, setshowFilterModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState(0);
  const [comment, setComment] = useState(null);
  const [transaction_type, settransaction_type] = useState("credit");
  const [transfer_type, settransfer_type] = useState("UPI");
  const { organisation, auth } = useAuth();

  let startyear = 2019;
  let endyear = new Date().getFullYear();
  let years = [];

  for (let i = startyear; i <= endyear; i++) {
    years.push({ year: i, label: `April ${i} - March ${i + 1}` });
  }

  const [filtermemberid, setFiltermemberid] = useState("all");
  const [filtertype, setFiltertype] = useState("all");
  const [selectedYearLabel, setSelectedYearLabel] = useState(
    years[years.length - 1].label
  );
  const [filterYearLabel, setFilterYearLabel] = useState(
    years[years.length - 1].label
  );
  const [startYear, setStartYear] = useState(
    Date.parse(
      new Date(
        new Date(
          new Date(new Date(new Date().setHours(0, 0, 0)).setDate(1)).setMonth(
            3
          )
        ).setFullYear(
          new Date().getMonth() < 2
            ? new Date().getFullYear() - 1
            : new Date().getFullYear()
        )
      )
    )
  );
  const [endYear, setEndYear] = useState(
    Date.parse(
      new Date(
        new Date(
          new Date(
            new Date(new Date().setHours(23, 59, 59)).setMonth(2)
          ).setDate(31)
        ).setFullYear(
          new Date().getMonth() > 2
            ? new Date().getFullYear() + 1
            : new Date().getFullYear()
        )
      )
    )
  );

  useEffect(() => {
    getInitialData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialData = () => {
    if (auth.user.role === "member" || props.parent === "details") {
      getTransactionDetails(props.selectedMember?._id);
    } else {
      getAllTransactionDetails();
      getAllMembersList();
    }
  };

  const getTransactionDetails = (id) => {
    axiosInstance
      .get(
        `/api/savings/${organisation._id}/gettransactionbyid/${id}?type=${filtertype}&startdate=${startYear}&enddate=${endYear}`
      )
      .then((response) => {
        setTransactionList(response.data.data);
        setTransactionMetadata(response.data.metadata);
        setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };

  const getAllTransactionDetails = () => {
    let mainurl = `/api/savings/${organisation._id}/getalltransactions`;
    // if (filtermemberid) {
    mainurl = `/api/savings/${organisation._id}/getalltransactions?member_id=${filtermemberid}&type=${filtertype}&startdate=${startYear}&enddate=${endYear}`;
    // }
    axiosInstance
      .get(mainurl)
      .then((response) => {
        setTransactionList(response.data.data);
        setTransactionMetadata(response.data.metadata);
        setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };
  const getAllMembersList = () => {
    axiosInstance
      .get(`/api/member/${organisation._id}/getallminimalmembers`)
      .then((response) => {
        setMembersList(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get members details. Try again!");
        console.log(error);
      });
  };
  const checkRequiredFields = () => {
    return date && typeof amount === "number";
  };
  const handleDelete = (id) => {
    setLoading(true);
    axiosInstance
      .delete(`/api/savings/${organisation._id}/deletetransaction/${id}`)
      .then((response) => {
        setLoading(false);
        toast("Member transaction deleted successfully");
        getInitialData();
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };

  const handleSubmit = () => {
    if (!checkRequiredFields()) {
      toast("Please fill all the required fields.");
    } else {
      setLoading(true);
      setShowModal(false);
      axiosInstance
        .post(
          `/api/savings/${organisation._id}/createTransaction/${
            (auth.user.role === "admin" || auth.user.role === "super-admin") &&
            props.parent !== "details"
              ? selectedMember
              : props.selectedMember?._id
          }`,
          {
            amount: parseInt(amount),
            date: Date.parse(date),
            comment: comment,
            type: transaction_type,
            transfer_type,
          }
        )
        .then((response) => {
          toast("Member transaction added successfully");
          getInitialData();
          console.log(response.data);
        })
        .catch((error) => {
          setLoading(false);
          toast("Unable to add saving transaction details. Try again!");
          console.log(error);
        });
    }
  };

  const renderDetailModal = () => {
    let date = new Date(showdetailModal.date).toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    return (
      <section className="savingscontainer-detailmodal-container">
        <div className="savingscontainer-detailmodal-content">
          <header className="savingscontainer-detailmodal-header">
            <ArrowIcon
              className="savingscontainer-modal-lefticon"
              onClick={() => setshowdetailModal(null)}
            />
            Transaction Details
          </header>
          <main className="savingscontainer-detailmodal-body">
            <div className="savingscontainer-detailmodal-form">
              <label>Transaction Id</label>
              <p>
                {filterTransationId(
                  showdetailModal.transaction_type,
                  showdetailModal.date,
                  showdetailModal._id
                )}
              </p>
            </div>
            <div className="savingscontainer-detailmodal-form">
              <label>Amount</label>
              <p>{showdetailModal.amount}</p>
            </div>
            <div className="savingscontainer-detailmodal-form">
              <label>Date</label>
              <p>{date}</p>
            </div>
            <div className="savingscontainer-detailmodal-form">
              <label>Transfer Type</label>
              <p>{showdetailModal.transfer_type}</p>
            </div>
            <div className="savingscontainer-detailmodal-form">
              <label>Closing Balance</label>
              <p>{showdetailModal.total_amount}</p>
            </div>
            <div className="savingscontainer-detailmodal-form">
              <label>Remarks</label>
              <p>{showdetailModal.comment || "-"}</p>
            </div>
          </main>
        </div>
      </section>
    );
  };

  const renderModal = () => {
    return (
      <motion.section
        className="savingscontainer-modal-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.2 }}
      >
        <header className="savingscontainer-modal-header">
          <ArrowIcon
            className="savingscontainer-modal-lefticon"
            onClick={() => setShowModal(false)}
          />
          Add Transaction
        </header>

        <main className="savingscontainer-modal-body">
          <AuthorizedContent roles={["admin", "super-admin"]}>
            {props.parent !== "details" ? (
              <div className="savingscontainer-form">
                <label>Member</label>
                <select
                  name="date"
                  onChange={({ target }) => {
                    console.log(target.value);
                    setSelectedMember(target.value);
                  }}
                  value={selectedMember}
                  required={true}
                >
                  <option value={null}>Select </option>
                  {membersList.map((member) => {
                    return (
                      <option value={member._id} key={member._id}>
                        {" "}
                        {member.full_name}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
          </AuthorizedContent>
          <div className="savingscontainer-form">
            <label>Date</label>
            <input
              type="date"
              name="date"
              onChange={({ target }) => {
                setDate(target.value);
              }}
              placeholder={date}
              value={date}
              required={true}
            />
          </div>
          <div className="savingscontainer-form">
            <label>Amount</label>
            <input
              type="text"
              name="amount"
              onChange={({ target }) => {
                setAmount(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="savingscontainer-form">
            <label>Transaction Type</label>
            <select
              onChange={({ target }) => {
                settransaction_type(target.value);
                if (target.value === "credit") {
                  settransfer_type("UPI");
                } else if (target.value === "debit") {
                  settransfer_type("SETTLEMENT");
                } else {
                  settransfer_type(null);
                }
              }}
            >
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
            </select>
          </div>
          <div className="savingscontainer-form">
            <label>Transfer Type</label>
            <input
              type="text"
              name="amount"
              value={transfer_type}
              disabled={true}
            />
          </div>
          <div className="savingscontainer-form">
            <label>Remarks</label>
            <input
              type="text"
              name="amount"
              onChange={({ target }) => {
                setComment(target.value);
              }}
              required={true}
            />
          </div>
        </main>
        <footer className="savingscontainer-modal-footer">
          {" "}
          <button className="savingscontainer-addbtn" onClick={handleSubmit}>
            Submit
          </button>
        </footer>
      </motion.section>
    );
  };

  const rendrFilterModal = (filterYear) => {
    // let filter = filterYear;
    return (
      <section className="savingscontainer-detailmodal-container">
        <div className="savingscontainer-detailmodal-content">
          <header className="savingscontainer-detailmodal-header">
            <ArrowIcon
              className="savingscontainer-modal-lefticon"
              onClick={() => {
                setshowFilterModal(false);
              }}
            />
            Filters
          </header>

          <main className="savingscontainer-detailmodal-body">
            <AuthorizedContent roles={["super-admin", "admin"]}>
              <div className="savingscontainer-form">
                <label>Users</label>
                <select
                  value={filtermemberid}
                  onChange={({ target }) => {
                    setFiltermemberid(target.value);
                  }}
                >
                  <option value={"all"}>All </option>
                  {membersList.map((member) => {
                    return (
                      <option value={member._id} key={member._id}>
                        {" "}
                        {member.full_name}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
            </AuthorizedContent>
            <div className="savingscontainer-form">
              <label>Type</label>
              <select
                value={filtertype}
                onChange={({ target }) => {
                  setFiltertype(target.value);
                }}
              >
                <option value={"all"}>All</option>

                <option value={"credit"}>Credit</option>
                <option value={"debit"}>Debit</option>
              </select>
            </div>
            <div className="savingscontainer-form">
              <label>Financial year</label>

              <select
                // value={filterYear}

                placeholder={filterYearLabel}
                onChange={({ target }) => {
                  setFilterYearLabel(JSON.parse(target.value).label);
                  setStartYear(JSON.parse(target.value).startdate);
                  setEndYear(JSON.parse(target.value).enddate);
                }}
              >
                <option value={null}></option>
                {years.reverse().map((data) => {
                  return (
                    <option
                      value={JSON.stringify({
                        label: data.label,
                        startdate: Date.parse(
                          new Date(
                            new Date(
                              new Date(
                                new Date(new Date().setHours(0, 0, 0)).setDate(
                                  1
                                )
                              ).setMonth(3)
                            ).setFullYear(data.year)
                          )
                        ),
                        enddate: Date.parse(
                          new Date(
                            new Date(
                              new Date(
                                new Date(
                                  new Date().setHours(23, 59, 59)
                                ).setMonth(2)
                              ).setDate(31)
                            ).setFullYear(data.year + 1)
                          )
                        ),
                      })}
                    >
                      {data.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </main>
          <footer className="savingscontainer-filter-footer">
            <button
              className="savingscontainer-addbtn savingscontainer-filter-footerbtn"
              onClick={() => {
                setFiltermemberid("all");
                setFiltertype("all");
              }}
            >
              Clear
            </button>
            <button
              className="savingscontainer-addbtn savingscontainer-filter-footerbtn"
              onClick={() => {
                setLoading(true);
                setshowFilterModal(false);
                setSelectedYearLabel(filterYear);
                getInitialData();
              }}
            >
              Apply
            </button>
          </footer>
        </div>
      </section>
    );
  };

  return (
    <>
      <AnimatePresence>{showModal ? renderModal() : null}</AnimatePresence>
      {showdetailModal ? renderDetailModal() : null}
      {showFilterModal ? rendrFilterModal(filterYearLabel) : null}
      <div className="savingscontainer-header">
        {props.parent !== "details" ? (
          <h3 className="savingscontainer-heading">Savings</h3>
        ) : null}
        <a
          href={`${baseUrl}/api/savings/${
            organisation._id
          }/exporttocsv?member_id=${
            auth.user.role === "admin" || auth.user.role === "super-admin"
              ? filtermemberid
                ? filtermemberid
                : "all"
              : props.selectedMember?._id
          }&type=all`}
          target="blank"
        >
          <button
            className="savingscontainer-filterbtn"
            // onClick={handleExportCSV}
          >
            <ExportIcon />
          </button>
        </a>
        <button
          className="savingscontainer-filterbtn"
          onClick={() => setshowFilterModal(true)}
        >
          <FilterIcon />
        </button>
        <AuthorizedContent roles={["super-admin", "admin"]}>
          <button
            className="savingscontainer-addiconbtn"
            onClick={() => setShowModal(true)}
          >
            <PlusIcon />
          </button>
        </AuthorizedContent>

        {/* <a href="upi://pay?pa=chandanramesh.g@ybl&amp;pn=chandan &amp;cu=INR" class="upi-pay1"> 
                 <button className="savingscontainer-addbtn">Add transaction</button>
           </a> */}
      </div>
      <div className="savingscontainer-wrapper">
        {loading ? (
          <div className="savingscontainer-loader">
            <FlipLoader />
          </div>
        ) : !transactionList?.length ? (
          <p>There are no transactions.</p>
        ) : (
          <div className="savingscontainer-listwrapper">
            <motion.header
              className="savingscontainer-listwrapper-header"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.1 }}
            >
              <label className="savingscontainer-listwrapper-headerleft">
                {selectedYearLabel}
                {/* Entries : <span>{transactionMetadata.count}</span> */}
              </label>
              <label className="savingscontainer-listwrapper-headerright">
                Net Balance : <span>₹ {transactionMetadata.net_balance}</span>{" "}
              </label>
            </motion.header>
            {transactionList?.map((transaction) => {
              let date = new Date(transaction.date).toLocaleDateString(
                "en-US",
                {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              );
              return (
                <motion.div
                  className="savingscontainer-list"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="savingscontainer-listleft">
                    <p className="savingscontainer-listleft-date">{date}</p>
                    {props.parent !== "details" &&
                    auth.user.role !== "member" ? (
                      <p className=" savingscontainer-listleft-user">
                        <ProfileIcon /> {transaction.member.first_name}{" "}
                        {transaction.member.last_name}
                      </p>
                    ) : null}
                    <p
                      className="savingscontainer-listleft-id"
                      onClick={() => setshowdetailModal(transaction)}
                    >
                      {filterTransationId(
                        transaction.transaction_type,
                        transaction.date,
                        transaction._id
                      )}
                    </p>
                  </div>
                  <label
                    className={
                      auth.user.role === "super-admin" ||
                      auth.user.role === "admin"
                        ? "savingscontainer-listrighttotal"
                        : "savingscontainer-listrighttotal savingscontainer-listrightmember"
                    }
                  >
                    <label
                      className={
                        transaction.type === "credit"
                          ? "savingscontainer-listright-amountcredit"
                          : "savingscontainer-listright-amountdebit"
                      }
                    >
                      <TransactionArrowIcon
                        className={
                          transaction.type === "credit"
                            ? "savingscontainer-crediticon"
                            : "savingscontainer-debiticon"
                        }
                      />{" "}
                      ₹ {transaction.amount}
                    </label>
                    <label className="savingscontainer-listright-closeamount">
                      <span>closing balance : </span>₹{transaction.total_amount}
                    </label>
                  </label>

                  <AuthorizedContent roles={["super-admin", "admin"]}>
                    <div
                      className="savingscontainer-listrightdelete"
                      onClick={() => handleDelete(transaction._id)}
                    >
                      <DeleteIcon />
                    </div>
                  </AuthorizedContent>
                </motion.div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default SavingsContainer;
