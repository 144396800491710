import React, { useEffect, useState } from "react";
import "./organisations.css";
import axiosInstance from "../../../helpers/axiosInstance";
import Loading from "../../../shared/loading/Loading";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BuildingIcon } from "../../../assets/signin/building.svg";
import { motion } from "framer-motion";

function Organisations() {
  const navigate = useNavigate();
  const { setOrganisation } = useAuth();
  const [loading, setLoading] = useState(true);
  const [addOrg, setaddOrg] = useState(false);
  const [name, setname] = useState(null);
  const [organisationList, setOrganisationList] = useState([]);
  useEffect(() => {
    getAllOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllOrganisations = () => {
    axiosInstance
      .get("/api/organisation/getallorganisation")
      .then((response) => {
        setLoading(false);
        setOrganisationList(response.data.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const handleOrgClick = (org) => {
    setOrganisation(org);
    localStorage.setItem("organisation", JSON.stringify(org));
    navigate("/admin/home", { replace: true });
  };

  const handleSubmit = () => {
    setLoading(true);
    axiosInstance
      .post("/api/organisation/upsertorganisation", { name: name })
      .then((response) => {
        setaddOrg(false);
        getAllOrganisations();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <section className="organisations">
      <div className="organisations-wrapper">
        <header className="organisations-header">
          <div className="organisations-headerleft">
            <h3 className="organisations-heading">Organisations</h3>
          </div>
        </header>
        <main className="organisations-content">
          <div className="organisation-flex-content">
            {loading ? (
              <div className="organisation-loader">
                <Loading />
              </div>
            ) : (
              organisationList?.map((org, idx) => {
                let date = new Date(org.start_date).toLocaleDateString(
                  "en-us",
                  {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }
                );
                return (
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3 }}
                    className="organisation-card"
                    key={idx}
                    onClick={() => handleOrgClick(org)}
                  >
                    <BuildingIcon />
                    <label className="organisation-card-header">
                      {org.name}
                    </label>
                    <label className="organisation-card-subheader">
                      {date}
                    </label>
                  </motion.div>
                );
              })
            )}
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="organisation-card organisation-card-addlabel"
            >
              {addOrg ? (
                <div className="organisation-card-addsection">
                  <input
                    type="text"
                    value={name}
                    onChange={({ target }) => setname(target.value)}
                  />
                  <div className="organisation-card-addfooter">
                    <button onClick={() => setaddOrg(false)}>x</button>
                    <button onClick={handleSubmit}>Submit</button>
                  </div>
                </div>
              ) : null}
              <p onClick={() => setaddOrg(true)}>+ Add Organisation</p>
            </motion.div>
          </div>
        </main>
      </div>
    </section>
  );
}

export default Organisations;
