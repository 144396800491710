

export const filterTransationId = (type,date,id) => {
    let newdate = new Date(date).toLocaleDateString("en-us", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
      let newtype = type|| "UPI" 
 
    let year = newdate.split("/")[2];
    let month = newdate.split("/")[0];
    let day = newdate.split("/")[1];
    let newid = id.substr(id.length - 5); 
    return `${newtype}/${year}${month}${day}/${newid}`;
}
