import axios from "axios";
import { baseUrl } from "./config";

const axiosInstance = axios.create({
  baseURL: baseUrl,
  // baseURL: `https://youthalliancefund-client-test.onrender.com`,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});
axiosInstance.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("accessToken")) {
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "accessToken"
      )}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    console.log(error);
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axios
        .post(`${baseUrl}/api/user/refreshAccessToken`, {
          refreshToken: localStorage.getItem("refreshToken"),
        })
        .then((res) => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.setItem("accessToken", res.data.accessToken);
          localStorage.setItem("refreshToken", res.data.refreshToken);
          // window.location.href = "/admin";
          // return res;
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.accessToken;
          return axiosInstance(originalRequest);
        })
        .catch((err) => {
          window.location.href = "/signin";
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("user");
          localStorage.removeItem("organisation");
          return Promise.reject(err);
        });
    }
    return Promise.reject(error);

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
  }
);

export default axiosInstance;
