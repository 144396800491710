import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [organisation, setOrganisation] = useState({});
  const [option, setOption] = useState("dashboard");

  return <AuthContext.Provider value={{ auth, organisation,option, setAuth, setOrganisation,setOption }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
