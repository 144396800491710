import React, { useRef, useState } from "react";
import "./applicationform.css";
import { ReactComponent as MainLogo } from "../../assets/signin/Logo.svg";
import Loading from "../../shared/loading/Loading";
import axiosInstance from "../../helpers/axiosInstance";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";

function ApplicationForm() {
  const [loading, setLoading] = useState(false);
  const emailRef = useRef("");
  const phnoRef = useRef("");
  const firstnameRef = useRef("");
  const lastnameRef = useRef("");
  const genderRef = useRef("");
  const addressRef = useRef("");
  const cityRef = useRef("");
  const nationalityRef = useRef("");
  const stateRef = useRef("");
  const pincodeRef = useRef("");
  const ageRef = useRef("");
  const navigate = useNavigate();
  const location = useLocation();
  const occupationRef = useRef("");
  const upiRef = useRef("");
  const refererRef = useRef("");
  const from = location.state?.from?.pathname || "/signin";

  function handleSubmit(e) {
    let data = {
      email: emailRef.current.value,
      phno: phnoRef.current.value,
      firstname: firstnameRef.current.value,
      lastname: lastnameRef.current.value,
      gender: genderRef.current.value,
      address: addressRef.current.value,
      city: cityRef.current.value,
      nationality: nationalityRef.current.value,
      state: stateRef.current.value,
      pincode: pincodeRef.current.value,
      age: ageRef.current.value,
      occupation: occupationRef.current.value,
      upi_id: upiRef.current.value,
      refer: refererRef.current.value,
    };
    setLoading(true);
    e.preventDefault();
    axiosInstance
      .post("/sendmail", data)
      .then((response) => {
        setLoading(false);

        toast("application submitted");
        navigate(from, { replace: true });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="applicationform-container">
      <MainLogo className="applicationform-main-logo" />
      <form className="applicationform-login-wrapper" onSubmit={handleSubmit}>
        <label>Application Form</label>
        <div className="applicationform-login-formsection">
          <input
            autoFocus
            tabIndex="1"
            type="text"
            ref={emailRef}
            placeholder="Email"
            autoComplete="off"
            name="email"
            required
          />
          <input
            tabIndex="2"
            type="text"
            ref={phnoRef}
            placeholder="Phone Number"
            autoComplete="off"
            name="phone_no"
            required
          />
          <input
            tabIndex="3"
            type="text"
            ref={firstnameRef}
            placeholder="First Name"
            autoComplete="off"
            name="first_name"
            required
          />
          <input
            tabIndex="4"
            type="text"
            ref={lastnameRef}
            placeholder="Last Name"
            autoComplete="off"
            name="last_name"
            required
          />

          <select
            tabIndex="5"
            type="text"
            ref={genderRef}
            placeholder="Gender"
            autoComplete="off"
            name="gender"
            required
          >
            <option>Male</option>
            <option>Female</option>
            <option>Other</option>
          </select>
          <input
            tabIndex="6"
            type="text"
            ref={ageRef}
            placeholder="Age"
            autoComplete="off"
            name="age"
            required
          />
          <input
            tabIndex="6"
            type="text"
            ref={occupationRef}
            placeholder="Occupation"
            autoComplete="off"
            name="occupation"
            required
          />
          <input
            tabIndex="7"
            type="text"
            ref={addressRef}
            placeholder="Address"
            autoComplete="off"
            name="address"
            required
          />
          <input
            tabIndex="8"
            type="text"
            ref={cityRef}
            placeholder="City"
            autoComplete="off"
            name="city"
            required
          />
          <input
            tabIndex="9"
            type="text"
            ref={stateRef}
            placeholder="State"
            autoComplete="off"
            name="state"
            required
          />

          <input
            tabIndex="10"
            type="text"
            ref={nationalityRef}
            placeholder="Nationality"
            autoComplete="off"
            name="nationality"
            required
          />
          <input
            tabIndex="11"
            type="text"
            ref={pincodeRef}
            placeholder="Pin Code"
            autoComplete="off"
            name="pin_code"
            required
          />
          <input
            tabIndex="11"
            type="text"
            ref={upiRef}
            placeholder="UPI id"
            autoComplete="off"
            name="upi"
            required
          />
          <input
            tabIndex="11"
            type="text"
            ref={refererRef}
            placeholder="Referer name"
            autoComplete="off"
            name="referer"
            required
          />
        </div>
        <button className="applicationform-login-btn" disabled={loading}>
          {loading ? <Loading /> : "Submit"}
        </button>
      </form>
    </div>
  );
}

export default ApplicationForm;
