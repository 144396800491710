import React from "react";
import "./maincontainer.css";
import Navbar from "./navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import Organisations from "./organisations/Organisations";
import RequireOrganisation from "../../hooks/RequireOrganisation";
import { Layout } from "../../shared/Layout";
import RequireAdmin from "../../hooks/RequireAdmin";
import Home from "./home/Home";
import Notfound from "../../shared/notfound/Notfound";

function MainContainer() {
  return (
    <div className="maincontainer">
      <Navbar />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route element={<RequireAdmin />}>
            <Route path="/*" element={<Home />} />
          </Route>
          <Route element={<RequireOrganisation />}>
            <Route path="/organisations" element={<Organisations />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default MainContainer;
