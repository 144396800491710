import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Signin from "./components/auth/Signin";
import { Layout } from "./shared/Layout";
import RequireAuth from "./hooks/RequireAuth";
import MainContainer from "./components/mainContainer/MainContainer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import useAuth from "./hooks/useAuth";
import { messaging } from "./firebase";
import { getToken } from "firebase/messaging";
import ApplicationForm from "./components/forms/ApplicationForm";
import ForgotPassword from "./components/forgotpassword/ForgotPassword";

function App() {
  const { setAuth, setOrganisation } = useAuth();
  const [fcmToken, setfcmToken] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const from =
    location.state?.from?.pathname || location.pathname === "/"
      ? "/admin/home"
      : location.pathname;

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      //generate
      const token = await getToken(messaging, {
        vapidKey:
          "BCOIVwxcIb3aPdVuIWo2TVEf1rVpnB3zL4ddxuC3biBuJZZOilCbnofuWa8PK4yv6KfRiVaEjszG_QAeB-ahS4E",
      });
      setfcmToken(token);
      console.log(token);
    } else if (permission === "denied") {
      alert("You denied for notification");
    }
  }

  useEffect(() => {
    requestPermission();
    // const messaging = firebase.messaging();
    // messaging
    //   .requestPermission()
    //   .then(() => {
    //     return messaging.getToken();
    //   })
    //   .then((token) => {
    //     console.log(token);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const user = localStorage.getItem("user");
    const organisation = localStorage.getItem("organisation");
    if (accessToken) {
      setAuth({
        user: JSON.parse(user),
        accessToken: accessToken,
        refreshToken: refreshToken,
      });
      let userObj = JSON.parse(user);
      if (userObj.role !== "super-admin") {
        setOrganisation(JSON.parse(organisation));
      }
    }
    console.log(location.pathname);
    if (location.pathname === "/application-form") {
      navigate("/application-form", { replace: true });
    } else {
      navigate(from, { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/application-form" element={<ApplicationForm />}></Route>
          <Route
            path="/signin"
            element={<Signin fcmToken={fcmToken} />}
          ></Route>
          <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
          <Route element={<RequireAuth />}>
            <Route path="/*" element={<MainContainer />}></Route>
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
