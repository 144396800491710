import React from "react";
import useAuth from "../hooks/useAuth";

function AuthorizedContent({ children, roles }) {
  const { auth } = useAuth();
  return (
    <>
      {roles.filter((role) => {
        return auth?.user?.role === role;
      })[0]
        ? children
        : null}
    </>
  );
}

export default AuthorizedContent;
