import React, { useState } from "react";
import "./navbar.css";
import { ReactComponent as MainLogo } from "../../../assets/signin/Logo1.svg";
import { ReactComponent as UserIcon } from "../../../assets/signin/user.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/signin/right.svg";

import { ReactComponent as HomeIcon } from "../../../assets/navbar/Home.svg";

import { ReactComponent as ProfileIcon } from "../../../assets/navbar/profile.svg";

import { ReactComponent as AboutIcon } from "../../../assets/navbar/about.svg";
import { ReactComponent as SignoutIcon } from "../../../assets/navbar/signout.svg";
import { ReactComponent as TelescopeIcon } from "../../../assets/navbar/telescope.svg";
import { ReactComponent as NotificationIcon } from "../../../assets/navbar/notification.svg";
import { ReactComponent as DocIcon } from "../../../assets/docs/docs.svg";
import useAuth from "../../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import AuthorizedContent from "../../../shared/AuthorizedContent";
import OutsideAlerter from "../../../helpers/outsideAlerter";
import { toast } from "react-toastify";
import { AnimatePresence, motion } from "framer-motion";

function Navbar() {
  const { auth, setAuth, setOrganisation, option, setOption } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [openTab, setOpenTab] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const handleSignOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    localStorage.removeItem("organisation");
    setAuth({});
    setOrganisation({});
    setOpenTab(false);
    setOption("dashboard");
    navigate("/signin", { replace: true });
  };
  const handleOption = (option) => {
    setOption(option);
    navigate(`/admin/${option}`);
  };

  const renderDetailModal = () => {
    return (
      <section className="navbar-detailmodal-container">
        <div className="navbar-detailmodal-content">
          <header className="navbar-detailmodal-header">
            <ArrowIcon
              className="navbar-modal-lefticon"
              onClick={() => setShowAbout(false)}
            />
            About Us
          </header>
          <main className="navbar-detailmodal-body">
            <div className="navbar-detailmodal-form">
              <label>Name</label>
              <p>Youth Alliance Fund</p>
            </div>
            <div className="navbar-detailmodal-form">
              <label>Email</label>
              <p>
                youthalliancefund@gmail.com{" "}
                <button
                  onClick={() => {
                    navigator.clipboard
                      .writeText("youthalliancefund@gmail.com")
                      .then(
                        function () {
                          toast("Email id copied to clipboard!");
                          console.log("Email id copied to clipboard!");
                        },
                        function (err) {
                          console.error(
                            "Async: Could not copy Email id: ",
                            err
                          );
                        }
                      );
                  }}
                >
                  copy
                </button>
              </p>
            </div>
            <div className="navbar-detailmodal-form">
              <label>Contact No</label>
              <p>
                0984-428-6170{" "}
                <button
                  onClick={() => {
                    navigator.clipboard.writeText("0984-428-6170").then(
                      function () {
                        toast("Contact No copied to clipboard!");
                        console.log("Contact No copied to clipboard!");
                      },
                      function (err) {
                        console.error(
                          "Async: Could not copy Contact No: ",
                          err
                        );
                      }
                    );
                  }}
                >
                  copy
                </button>
              </p>
            </div>
            <div className="navbar-detailmodal-form">
              <label>UPI</label>
              <p>
                9844286170@upi{" "}
                <button
                  onClick={() => {
                    navigator.clipboard.writeText("9844286170@upi").then(
                      function () {
                        toast("UPI id copied to clipboard!");
                        console.log("UPI id copied to clipboard!");
                      },
                      function (err) {
                        console.error("Async: Could not copy UPI id: ", err);
                      }
                    );
                  }}
                >
                  copy
                </button>
              </p>
            </div>
            <div className="navbar-detailmodal-form">
              <label>Website</label>
              <p>
                <a
                  target="_blank"
                  href="https://youthalliancefund.in/"
                  rel="noreferrer"
                >
                  https://youthalliancefund.in/
                </a>
              </p>
            </div>
          </main>
        </div>
      </section>
    );
  };

  return (
    <>
      <motion.div
        className="navbar"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        {showAbout ? renderDetailModal() : null}
        <AuthorizedContent roles={["super-admin"]}>
          {location.pathname !== "/organisations" ? (
            <ArrowIcon
              className="navbar-arrow"
              onClick={() => {
                setOrganisation({});
                localStorage.removeItem("organisation");
                navigate("/organisations", { replace: true });
              }}
            />
          ) : null}
        </AuthorizedContent>
        <MainLogo className="navbar-logo" />
        {location.pathname !== "/organisations" ? (
          <>
            <nav className="navbarlink-container">
              <label
                onClick={() => handleOption("home")}
                className={
                  location.pathname === "/admin/home"
                    ? "navbarlink-container-label navbarlink-container-label-active"
                    : "navbarlink-container-label"
                }
              >
                Dashboard
              </label>
              <label
                onClick={() => handleOption("notifications")}
                className={
                  location.pathname === "/admin/notifications"
                    ? "navbarlink-container-label navbarlink-container-label-active"
                    : "navbarlink-container-label"
                }
              >
                Notifications
              </label>
              <label
                onClick={() => handleOption("explore")}
                className={
                  option === "explore" || location.pathname === "/admin/explore"
                    ? "navbarlink-container-label navbarlink-container-label-active"
                    : "navbarlink-container-label"
                }
              >
                Explore
              </label>
              {/* <label
              onClick={() => handleOption("save")}
              className={
                location.pathname === "/admin/save"
                  ? "navbarlink-container-label navbarlink-container-label-active"
                  : "navbarlink-container-label"
              }
            >
              Saving
            </label>
            <label
              onClick={() => handleOption("loans")}
              className={
                location.pathname === "/admin/loans"
                  ? "navbarlink-container-label navbarlink-container-label-active"
                  : "navbarlink-container-label"
              }
            >
              Loans
            </label>
            <label
              onClick={() => handleOption("repayment")}
              className={
                location.pathname === "/admin/repayment"
                  ? "navbarlink-container-label navbarlink-container-label-active"
                  : "navbarlink-container-label"
              }
            >
              Repayment
            </label> */}
              {auth?.user?.role !== "super-admin" &&
              auth?.user?.role !== "admin" ? (
                <label
                  onClick={() => handleOption("profile")}
                  className={
                    location.pathname === "/admin/profile"
                      ? "navbarlink-container-label navbarlink-container-label-active"
                      : "navbarlink-container-label"
                  }
                >
                  Profile
                </label>
              ) : null}
            </nav>
          </>
        ) : null}
        <div className="navbar-tabsection">
          {auth.user.imageUrl ? (
            <img
              src={auth.user.imageUrl}
              className="navbar-user-profileimage"
              alt="profile"
              onClick={() => setOpenTab((prevState) => !prevState)}
            />
          ) : (
            <UserIcon
              className="navbar-user"
              onClick={() => setOpenTab((prevState) => !prevState)}
            />
          )}
          <AnimatePresence>
            {openTab && (
              <OutsideAlerter
                resetAlert={() =>
                  setTimeout(() => {
                    setOpenTab(false);
                  }, 100)
                }
              >
                <motion.div
                  className="navbar-tab-buttons"
                  initial={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="navbar-tab-content">
                    {auth.user.imageUrl ? (
                      <img
                        src={auth.user.imageUrl}
                        className="navbar-user-profileimage"
                        alt="profile"
                        onClick={() => setOpenTab((prevState) => !prevState)}
                      />
                    ) : (
                      <UserIcon
                        className="navbar-user"
                        onClick={() => setOpenTab((prevState) => !prevState)}
                      />
                    )}
                    <div className="navbar-tab-contentdata">
                      <label>
                        {auth.user.first_name} {auth.user.last_name}
                      </label>
                      <p>{auth.user.role} </p>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setShowAbout(true);
                      setOpenTab(false);
                    }}
                  >
                    <AboutIcon /> About Us
                  </button>
                  <a
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    href="https://firebasestorage.googleapis.com/v0/b/newstorage-d1969.appspot.com/o/Youth%20Alliance%20Fund%20Terms%20and%20Conditions.pdf?alt=media&token=b06379e4-453a-48a1-bacd-2198e243dbc8"
                  >
                    <button
                      onClick={() => {
                        // setShowAbout(true);
                        setOpenTab(false);
                      }}
                    >
                      <DocIcon /> Terms and Conditions
                    </button>
                  </a>
                  <button onClick={handleSignOut}>
                    {" "}
                    <SignoutIcon /> Signout
                  </button>
                </motion.div>
              </OutsideAlerter>
            )}
          </AnimatePresence>
        </div>
      </motion.div>
      {location.pathname !== "/organisations" ? (
        <motion.div
          className="navbar-mobile-container"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div
            onClick={() => handleOption("home")}
            className={
              location.pathname === "/admin/home"
                ? "navbar-mobile-tabs navbar-mobile-tabsactive"
                : "navbar-mobile-tabs"
            }
          >
            {location.pathname === "/admin/home" ? <span></span> : null}
            <HomeIcon className="navbar-mobile-tab_icons" />
            <label>Home</label>
          </div>

          <div
            onClick={() => handleOption("notifications")}
            className={
              location.pathname === "/admin/notifications"
                ? "navbar-mobile-tabs navbar-mobile-tabsactive"
                : "navbar-mobile-tabs"
            }
          >
            {location.pathname === "/admin/notifications" ? (
              <span></span>
            ) : null}
            <NotificationIcon className="navbar-mobile-tab_icons " />
            <label>Notifications</label>
          </div>

          <div
            onClick={() => handleOption("explore")}
            className={
              option === "explore" || location.pathname === "/admin/explore"
                ? "navbar-mobile-tabs navbar-mobile-tabsactive"
                : "navbar-mobile-tabs"
            }
          >
            {option === "explore" || location.pathname === "/admin/explore" ? (
              <span></span>
            ) : null}
            <TelescopeIcon className="navbar-mobile-tab_icons " />
            <label>Explore</label>
          </div>

          {auth?.user?.role !== "super-admin" &&
          auth?.user?.role !== "admin" ? (
            <div
              onClick={() => handleOption("profile")}
              className={
                location.pathname === "/admin/profile"
                  ? "navbar-mobile-tabs navbar-mobile-tabsactive"
                  : "navbar-mobile-tabs"
              }
            >
              {location.pathname === "/admin/profile" ? <span></span> : null}
              <ProfileIcon className="navbar-mobile-tab_icons " />
              <label>Profile</label>
            </div>
          ) : null}
        </motion.div>
      ) : null}
    </>
  );
}

export default Navbar;
