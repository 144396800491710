export const filterLoanID = (id,date) => {
    let newdate = new Date(date).toLocaleDateString("en-us", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
 
    let year = newdate.split("/")[2];
    let month = newdate.split("/")[1];
    let day = newdate.split("/")[0];

    let newid = id.substr(id.length - 5); 
    return `${year}${month}${day}${newid}`;
    
}