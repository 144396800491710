import React, { useEffect, useState } from "react";
import "./loancontainer.css";
import { toast } from "react-toastify";
import axiosInstance from "../../../../../helpers/axiosInstance";
import AuthorizedContent from "../../../../../shared/AuthorizedContent";
import FlipLoader from "../../../../../shared/fliploader/FlipLoader";
import { ReactComponent as ArrowIcon } from "../../../../../assets/signin/right.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/signin/delete.svg";
import { ReactComponent as ProfileIcon } from "../../../../../assets/navbar/profile.svg";
import { ReactComponent as FilterIcon } from "../../../../../assets/filter.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/plus.svg";
import useAuth from "../../../../../hooks/useAuth";
import Switch from "react-switch";
import { filterLoanID } from "../../../../../helpers/filterLoanId";
import { AnimatePresence, motion } from "framer-motion";

function LoanContainer(props) {
  const [transactionList, setTransactionList] = useState([]);
  const [transactionMetdata, setTransactionMetdata] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateloading, setUpdateLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [showFilterModal, setshowFilterModal] = useState(false);
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState(0);
  const [term, setTerm] = useState(0);
  const [interest, setInterest] = useState(0);
  const [active, setActive] = useState(true);
  const [comment, setComment] = useState(null);
  const [sanction_date, setSanctionDate] = useState(new Date());
  const [end_date, setEndDate] = useState(new Date(new Date().setMonth() + 3));
  const { organisation, auth } = useAuth();

  const [filtermemberid, setFiltermemberid] = useState("all");
  const [filteractive, setFilteractive] = useState("all");

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialData = () => {
    if (auth.user.role === "member" || props.parent === "details") {
      getTransactionDetails();
    } else {
      getAllTransactionDetails();
      getAllMembersList();
    }
  };

  const getTransactionDetails = () => {
    axiosInstance
      .get(
        `/api/loans/${organisation._id}/gettransactionbyid/${props.selectedMember?._id}?active=${filteractive}`
      )
      .then((response) => {
        setTransactionList(response.data.data);
        setTransactionMetdata(response.data.metadata);
        setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };
  const getAllTransactionDetails = () => {
    axiosInstance
      .get(
        `/api/loans/${organisation._id}/getalltransactions?member_id=${
          filtermemberid || ""
        }&active=${filteractive}`
      )
      .then((response) => {
        setTransactionList(response.data.data);
        setTransactionMetdata(response.data.metadata);
        setLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };
  const getAllMembersList = () => {
    axiosInstance
      .get(`/api/member/${organisation._id}/getallminimalmembers`)
      .then((response) => {
        setMembersList(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get members details. Try again!");
        console.log(error);
      });
  };

  const handleDelete = (id) => {
    setLoading(true);
    axiosInstance
      .delete(`/api/loans/${organisation._id}/deletetransaction/${id}`)
      .then((response) => {
        setLoading(false);
        toast("Member transaction deleted successfully");
        getInitialData();
        console.log(response.data);
      })
      .catch((error) => {
        setLoading(false);
        toast("Unable to get saving transactions details. Try again!");
        console.log(error);
      });
  };

  const checkRequiredFields = () => {
    return (
      date &&
      typeof amount === "number" &&
      amount >= 5000 &&
      amount <= 25000 &&
      term >= 3 &&
      typeof term === "number" &&
      typeof interest === "number" &&
      sanction_date &&
      end_date
    );
  };

  const handleSubmit = () => {
    console.log(checkRequiredFields());
    if (!checkRequiredFields()) {
      toast("Please fill all the required fields.");
    } else {
      setLoading(true);
      setShowModal(false);
      axiosInstance
        .post(
          `/api/loans/${organisation._id}/createtransaction/${
            (auth.user.role === "admin" || auth.user.role === "super-admin") &&
            props.parent !== "details"
              ? selectedMember
              : props.selectedMember?._id
          }`,
          {
            amount: parseInt(amount),
            date: Date.parse(date),
            term,
            interest,
            comment,
            sanction_date: Date.parse(sanction_date),
            end_date: Date.parse(end_date),
          }
        )
        .then((response) => {
          toast("Member transaction added successfully");
          getInitialData();
          console.log(response.data);
        })
        .catch((error) => {
          setLoading(false);
          toast("Unable to add saving transaction details. Try again!");
          console.log(error);
        });
    }
  };
  const handleChange = (checked) => {
    setUpdateLoading(true);
    setActive(checked);
    axiosInstance
      .post(
        `/api/loans/${organisation._id}/changeloanstatus/${selectedDetail?._id}`,
        {
          active: checked,
        }
      )
      .then((response) => {
        setUpdateLoading(false);
        const newTransaction = transactionList.map((item) => {
          if (item._id === selectedDetail._id) {
            item.active = checked;
            if (checked) {
              ++transactionMetdata.active_loan;
              --transactionMetdata.closed_loan;
            } else if (!checked) {
              --transactionMetdata.active_loan;
              ++transactionMetdata.closed_loan;
            }
          }
          return item;
        });
        console.log(newTransaction);
        setTransactionList(newTransaction);
      })
      .catch((err) => {
        setUpdateLoading(false);
      });
  };

  const renderDetailModal = () => {
    let date = new Date(selectedDetail.date).toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    let sanction_date = new Date(
      selectedDetail.sanction_date
    ).toLocaleDateString("en-us", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    let end_date = new Date(selectedDetail.end_date).toLocaleDateString(
      "en-us",
      { weekday: "long", year: "numeric", month: "long", day: "numeric" }
    );

    return (
      <section className="loanscontainer-detailmodal-container">
        <div className="loanscontainer-detailmodal-content">
          <header className="loanscontainer-detailmodal-header">
            <ArrowIcon
              className="loanscontainer-modal-lefticon"
              onClick={() => setSelectedDetail(null)}
            />
            Loan Transaction Details
          </header>
          <main className="loanscontainer-detailmodal-body">
            <div className="loanscontainer-detailmodal-form">
              <label>Loan Id</label>
              <p>{filterLoanID(selectedDetail._id, selectedDetail.date)}</p>
            </div>
            <div className="loanscontainer-detailmodal-form">
              <label>Amount</label>
              <p>{selectedDetail.amount}</p>
            </div>
            <div className="loanscontainer-detailmodal-form">
              <label>Date</label>
              <p>{date}</p>
            </div>
            <div className="loanscontainer-detailmodal-form">
              <label>Term</label>
              <p>{selectedDetail.term}</p>
            </div>
            <div className="loanscontainer-detailmodal-form">
              <label>Interest</label>
              <p>{selectedDetail.interest}</p>
            </div>
            <div className="loanscontainer-detailmodal-form">
              <label>Pending_loan</label>
              <p>{selectedDetail.pending_amount}</p>
            </div>
            <div className="loanscontainer-detailmodal-form">
              <label>Total Amount</label>
              <p>{selectedDetail.total_amount}</p>
            </div>
            <div className="loanscontainer-detailmodal-form">
              <label>Sanctioned Date</label>
              <p>{sanction_date}</p>
            </div>
            <div className="loanscontainer-detailmodal-form">
              <label>Loan Closing Date</label>
              <p>{end_date}</p>
            </div>
            <div className="loanscontainer-detailmodal-form">
              <label>Remarks</label>
              <p>{selectedDetail.comment || "-"}</p>
            </div>
            <AuthorizedContent roles={["super-admin", "admin"]}>
              <div className="loanscontainer-detailmodal-form">
                <label>Loan Status</label>{" "}
                <Switch
                  onChange={handleChange}
                  checked={active}
                  offColor={"#c20000"}
                  disabled={updateloading}
                />
              </div>
            </AuthorizedContent>
          </main>
        </div>
      </section>
    );
  };

  const renderModal = () => {
    return (
      <motion.section
        className="loanscontainer-modal-container"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.2 }}
      >
        <header className="loanscontainer-modal-header">
          <ArrowIcon
            className="loanscontainer-modal-lefticon"
            onClick={() => setShowModal(false)}
          />
          Add Loan Transaction
        </header>
        <main className="loanscontainer-modal-body">
          <AuthorizedContent roles={["admin", "super-admin"]}>
            {props.parent !== "details" ? (
              <div className="loanscontainer-form">
                <label>Member</label>
                <select
                  name="date"
                  onChange={({ target }) => {
                    console.log(target.value);
                    setSelectedMember(target.value);
                  }}
                  value={selectedMember}
                  required={true}
                >
                  <option value={null}>Select </option>
                  {membersList.map((member) => {
                    return (
                      <option value={member._id} key={member._id}>
                        {" "}
                        {member.full_name}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : null}
          </AuthorizedContent>
          <div className="loanscontainer-form">
            <label>Date</label>
            <input
              type="date"
              name="date"
              onChange={({ target }) => {
                setDate(target.value);
              }}
              // placeholder={date}
              value={date}
              required={true}
            />
          </div>
          <div className="loanscontainer-form">
            <label>Amount</label>
            <input
              type="text"
              name="amount"
              onChange={({ target }) => {
                setAmount(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="loanscontainer-form">
            <label>Term (in months)</label>
            <input
              type="text"
              name="term"
              onChange={({ target }) => {
                setTerm(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="loanscontainer-form">
            <label>Interest Amount</label>
            <input
              type="text"
              name="interest"
              onChange={({ target }) => {
                setInterest(parseInt(target.value));
              }}
              required={true}
            />
          </div>
          <div className="loanscontainer-form">
            <label>Sanction Date</label>
            <input
              type="date"
              name="date"
              onChange={({ target }) => {
                setSanctionDate(target.value);
              }}
              // placeholder={sanction_date}
              value={sanction_date}
              required={true}
            />
          </div>
          <div className="loanscontainer-form">
            <label>End Date</label>
            <input
              type="date"
              name="date"
              onChange={({ target }) => {
                setEndDate(target.value);
              }}
              // placeholder={end_date}
              value={end_date}
              required={true}
            />
          </div>
          <div className="loanscontainer-form">
            <label>Remarks</label>
            <input
              type="text"
              name="date"
              onChange={({ target }) => {
                setComment(target.value);
              }}
              // placeholder={end_date}
              value={comment}
              required={true}
            />
          </div>
        </main>
        <footer className="loanscontainer-modal-footer">
          {" "}
          <button className="loanscontainer-addbtn" onClick={handleSubmit}>
            Submit
          </button>
        </footer>
      </motion.section>
    );
  };

  const rendrFilterModal = () => {
    return (
      <section className="loanscontainer-detailmodal-container">
        <div className="loanscontainer-detailmodal-content">
          <header className="loanscontainer-detailmodal-header">
            <ArrowIcon
              className="loanscontainer-modal-lefticon"
              onClick={() => {
                setshowFilterModal(false);
              }}
            />
            Filters
          </header>

          <main className="loanscontainer-detailmodal-body">
            <AuthorizedContent roles={["super-admin", "admin"]}>
              <div className="loanscontainer-form">
                <label>Users</label>
                <select
                  value={filtermemberid}
                  onChange={({ target }) => {
                    setFiltermemberid(target.value);
                  }}
                >
                  <option value={"all"}>All </option>
                  {membersList.map((member) => {
                    return (
                      <option value={member._id} key={member._id}>
                        {" "}
                        {member.full_name}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
            </AuthorizedContent>
            <div className="loanscontainer-form">
              <label>Status</label>
              <select
                value={filteractive}
                onChange={({ target }) => {
                  setFilteractive(target.value);
                }}
              >
                <option value={"all"}>All</option>
                <option value={true}>Active</option>
                <option value={false}>Closed</option>
              </select>
            </div>
          </main>
          <footer className="loanscontainer-filter-footer">
            <button
              className="loanscontainer-addbtn loanscontainer-filter-footerbtn"
              onClick={() => {
                setFiltermemberid("all");
                setFilteractive("all");
              }}
            >
              Clear
            </button>
            <button
              className="loanscontainer-addbtn loanscontainer-filter-footerbtn"
              onClick={() => {
                setLoading(true);
                getInitialData();
                setshowFilterModal(false);
              }}
            >
              Apply
            </button>
          </footer>
        </div>
      </section>
    );
  };

  return (
    <>
      <AnimatePresence>{showModal ? renderModal() : null}</AnimatePresence>
      {selectedDetail ? renderDetailModal() : null}
      {showFilterModal ? rendrFilterModal() : null}
      <div className="loanscontainer-header">
        {props.parent !== "details" ? (
          <h3 className="loanscontainer-heading">Loans</h3>
        ) : null}
        <button
          className="loanscontainer-filterbtn"
          onClick={() => setshowFilterModal(true)}
        >
          <FilterIcon />
        </button>
        <AuthorizedContent roles={["super-admin", "admin"]}>
          <button
            className="loanscontainer-addiconbtn"
            onClick={() => setShowModal(true)}
          >
            <PlusIcon />
          </button>
        </AuthorizedContent>
      </div>
      <div className="loanscontainer-wrapper">
        {loading ? (
          <div className="loanscontainer-loader">
            <FlipLoader />
          </div>
        ) : !transactionList?.length ? (
          <p>There are no loan transactions.</p>
        ) : (
          <div className="loanscontainer-listwrapper">
            <motion.header
              className="loanscontainer-listwrapper-header"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.1 }}
            >
              <label className="loanscontainer-listwrapper-headerleft">
                <label>
                  Active : <span>{transactionMetdata.active_loan}</span>
                </label>{" "}
                <label>
                  Closed : <span>{transactionMetdata.closed_loan}</span>
                </label>
              </label>
              <label className="loanscontainer-listwrapper-headerright">
                Total Loan Amount :{" "}
                <span>₹ {transactionMetdata.total_loan}</span>{" "}
              </label>
            </motion.header>
            {transactionList?.map((transaction, idx) => {
              let date = new Date(transaction.date).toLocaleDateString(
                "en-us",
                {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              );
              return (
                <motion.div
                  className="loanscontainer-list"
                  key={idx}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="loanscontainer-listleft">
                    <p className="loanscontainer-listleft-date">{date}</p>
                    {props.parent !== "details" &&
                    auth.user.role !== "member" ? (
                      <p className=" loanscontainer-listleft-user">
                        <ProfileIcon /> {transaction.member.first_name}{" "}
                        {transaction.member.last_name}
                      </p>
                    ) : null}

                    <p
                      className="loanscontainer-listleft-id"
                      onClick={() => {
                        setSelectedDetail(transaction);
                        setActive(transaction.active);
                      }}
                    >
                      {filterLoanID(transaction._id, transaction.date)}{" "}
                      <span
                        className={
                          transaction.active
                            ? "loanscontainer-listleft-status loanscontainer-listleft-status-open"
                            : "loanscontainer-listleft-status loanscontainer-listleft-status-closed"
                        }
                      >
                        <span></span>
                        {transaction.active ? "Active" : "Closed"}
                      </span>{" "}
                    </p>
                  </div>
                  <label
                    className={
                      auth.user.role === "super-admin" ||
                      auth.user.role === "admin"
                        ? "loanscontainer-listrighttotal"
                        : "loanscontainer-listrighttotal loanscontainer-listrightmember"
                    }
                  >
                    ₹ {transaction.total_amount}
                  </label>

                  <AuthorizedContent roles={["super-admin", "admin"]}>
                    <div
                      className="loanscontainer-listrightdelete"
                      onClick={() => handleDelete(transaction._id)}
                    >
                      <DeleteIcon />
                    </div>
                  </AuthorizedContent>
                </motion.div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
}

export default LoanContainer;
