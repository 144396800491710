import React, { useEffect, useRef, useState } from "react";
import "./memberprofile.css";
import axiosInstance from "../../../../../helpers/axiosInstance";
import useAuth from "../../../../../hooks/useAuth";
import { toast } from "react-toastify";
import Loading from "../../../../../shared/loading/Loading";
import AuthorizedContent from "../../../../../shared/AuthorizedContent";
import userimage from "../../../../../assets/signin/user.svg";
import FlipLoader from "../../../../../shared/fliploader/FlipLoader";
import { ReactComponent as ArrowIcon } from "../../../../../assets/signin/right.svg";
import { motion } from "framer-motion";

function MemberProfile(props) {
  const [loading, setLoading] = useState(false);
  const [main_loading, setMainLoading] = useState(true);
  const [incomplete, setIncomlete] = useState(false);
  const oldPassword = useRef();
  const confirmPassword = useRef();
  const newPassword = useRef();
  const { auth, organisation } = useAuth();
  const [image, setImage] = useState();
  const [showImage, setshowImage] = useState(false);
  const mounted = useRef(false);
  const [selectedMember, setSelectedMember] = useState({});

  useEffect(() => {
    getMemberDetails(props.selectedMember?._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMemberDetails = (id) => {
    axiosInstance
      .get(`/api/member/${organisation._id}/getmember/${id}`)
      .then((response) => {
        setSelectedMember(response.data[0]);
        setMainLoading(false);
        mounted.current = true;
        console.log(response.data);
      })
      .catch((error) => {
        setMainLoading(false);
        mounted.current = true;
        toast("Unable to get member details. Try again!");
        console.log(error);
      });
  };

  const handleOnChange = ({ target }) => {
    const selectedMemberObj = {
      ...selectedMember,
      [target.name]: target.value,
    };
    setSelectedMember(selectedMemberObj);
  };

  const handleSubmit = () => {
    if (checkRequired() && validatePhoneNo() && validateEmail()) {
      setLoading(true);
      const selectedMemberObj = {
        email: selectedMember?.email.toString().toLowerCase(),
        ...selectedMember,
      };

      axiosInstance
        .post(`/api/member/${organisation._id}/update`, selectedMemberObj)
        .then((response) => {
          setLoading(false);
          if (props.page !== "profile") {
            props.updateParent();
          }
          getMemberDetails(props.selectedMember?._id);
          toast("Member details updated successfully");
          console.log(response.data);
        })
        .catch((error) => {
          setLoading(false);
          toast("Unable to update member details. Try again!");
          console.log(error);
        });
    } else {
      toast("Fill up all required fields. Try again!");
    }
  };

  const handleDelete = () => {
    axiosInstance
      .delete(`/api/member/${organisation._id}/${selectedMember._id}`)
      .then((response) => {
        toast("Member deleted successfully");
        props.goBack();
        console.log(response.data);
      })
      .catch((error) => {
        toast("Unable to delete member. Try again!");
        console.log(error);
      });
  };

  const checkRequired = () => {
    return (
      selectedMember.member_id &&
      selectedMember.first_name &&
      selectedMember?.last_name &&
      selectedMember.gender &&
      selectedMember.email &&
      selectedMember.role &&
      selectedMember?.phone_no
    );
  };

  const validatePhoneNo = () => {
    return selectedMember?.phone_no?.match(/^\d{10}$/);
  };

  const validateEmail = () => {
    return selectedMember?.email?.match(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
  };

  const checkChangePassword = () => {
    if (
      oldPassword.current.value &&
      confirmPassword.current.value &&
      newPassword.current.value
    ) {
      if (oldPassword.current.value === confirmPassword.current.value) {
        setIncomlete(false);
        return true;
      } else {
        toast("Password doesn't match.");
        setIncomlete(true);
        return false;
      }
    } else {
      setIncomlete(true);
      toast("Fill up all required fields. Try again!");
      return false;
    }
  };

  const handleChangePassword = () => {
    if (checkChangePassword()) {
      const passwordObj = {
        old_password: oldPassword.current.value,
        new_password: newPassword.current.value,
      };
      axiosInstance
        .post(
          `/api/member/${organisation._id}/changepassword/${selectedMember._id}`,
          passwordObj
        )
        .then((response) => {
          toast("Member password changed successfully");
          oldPassword.current.value = "";
          confirmPassword.current.value = "";
          newPassword.current.value = "";
          getMemberDetails(props.selectedMember?._id);
        })
        .catch((error) => {
          toast("Unable to change password. Try again!");
          console.log(error);
        });
    }
  };

  const uploadImage = () => {
    setLoading(true);
    let form = new FormData();
    form.append("profile", image);
    console.log(form);
    axiosInstance
      .post(
        `/api/member/${organisation._id}/updateimage/${selectedMember._id}`,
        form
      )
      .then((response) => {
        setLoading(false);
        getMemberDetails(props.selectedMember?._id);
        if (props.parent === "details") props.updateParent();
        toast("Member profile image updated successfully");
        console.log(response.data);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to update profile image.");
        console.log(err);
      });
  };

  const renderImagePopup = () => {
    return (
      <div className="memberdetails-image-container">
        <p onClick={() => setshowImage(false)}>
          {" "}
          <ArrowIcon className="memberdetails-image-back" /> Back
        </p>

        <img src={selectedMember?.imageUrl} alt="profile" />
      </div>
    );
  };

  return (
    <>
      {showImage ? renderImagePopup() : null}
      {main_loading ? (
        <div className="memberdetails-loader">
          <FlipLoader />
        </div>
      ) : (
        <>
          <motion.div
            className="memberdetails-image-section"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            <img
              onClick={() =>
                selectedMember?.imageUrl ? setshowImage(true) : null
              }
              src={selectedMember?.imageUrl || userimage}
              // src={image}
              alt="profile"
              // onError={(e) => {
              //   console.log(e.target.src)((e.target.src = userimage));
              // }}
            />
          </motion.div>

          <motion.section
            className="memberdetails"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="memberdetails-form">
              <label className="required">Member Id</label>
              <input
                className={
                  incomplete || !selectedMember?.member_id
                    ? "input-incomplete"
                    : ""
                }
                type="text"
                name="member_id"
                onChange={handleOnChange}
                value={selectedMember.member_id}
                disabled={
                  auth?.user?.role === "member" ||
                  auth?.user?.role === "president" ||
                  auth?.user?.role === "secretary"
                }
                required
              />
            </div>
            <div className="memberdetails-form">
              <label className="required">First Name</label>
              <input
                className={
                  incomplete || !selectedMember?.first_name
                    ? "input-incomplete"
                    : ""
                }
                type="text"
                name="first_name"
                onChange={handleOnChange}
                value={selectedMember.first_name}
                required
              />
            </div>
            <div className="memberdetails-form">
              <label className="required">Last Name</label>
              <input
                type="text"
                className={
                  incomplete || !selectedMember?.last_name
                    ? "input-incomplete"
                    : ""
                }
                name="last_name"
                onChange={handleOnChange}
                value={selectedMember.last_name}
              />
            </div>
            <div className="memberdetails-form">
              <label className="required">Role</label>
              <select
                name="role"
                className={
                  incomplete || !selectedMember?.role ? "input-incomplete" : ""
                }
                onChange={handleOnChange}
                value={selectedMember?.role}
                disabled={
                  auth?.user?.role === "member" ||
                  auth?.user?.role === "president" ||
                  auth?.user?.role === "secretary"
                }
                required
              >
                <option value={null}> </option>
                <AuthorizedContent
                  roles={["super-admin", "admin", "president"]}
                >
                  <option key={"president"} value={"president"}>
                    President
                  </option>
                </AuthorizedContent>
                <AuthorizedContent
                  roles={["super-admin", "president", "admin"]}
                >
                  <option key={"admin"} value={"admin"}>
                    Admin
                  </option>
                </AuthorizedContent>
                <AuthorizedContent
                  roles={["super-admin", "president", "admin", "secretary"]}
                >
                  <option key={"secretary"} value={"secretary"}>
                    Secretary
                  </option>
                </AuthorizedContent>

                <option key={"member"} value={"member"}>
                  Member
                </option>
              </select>
            </div>
            <div className="memberdetails-form">
              <label className="required">Gender</label>
              <select
                name="gender"
                className={
                  incomplete || !selectedMember?.gender
                    ? "input-incomplete"
                    : ""
                }
                onChange={handleOnChange}
                value={selectedMember?.gender}
                required
              >
                <option value={null}> </option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option>
              </select>
            </div>
            <div className="memberdetails-form">
              <label>Address</label>
              <input
                type="text"
                name="address"
                onChange={handleOnChange}
                value={selectedMember.address}
              />
            </div>
            <div className="memberdetails-form">
              <label>State</label>
              <input
                type="text"
                name="state"
                onChange={handleOnChange}
                value={selectedMember.state}
              />
            </div>
            <div className="memberdetails-form">
              <label>City</label>
              <input
                type="text"
                name="city"
                onChange={handleOnChange}
                value={selectedMember.city}
              />
            </div>
            <div className="memberdetails-form">
              <label>Nationality</label>
              <input
                type="text"
                name="nationality"
                onChange={handleOnChange}
                value={selectedMember.nationality}
              />
            </div>
            <div className="memberdetails-form">
              <label>Pin Code</label>
              <input
                type="text"
                name="pin_code"
                onChange={handleOnChange}
                value={selectedMember.pin_code}
              />
            </div>
            <div className="memberdetails-form">
              <label>Age</label>
              <input
                type="text"
                name="age"
                onChange={handleOnChange}
                value={selectedMember.age}
              />
            </div>
            <div className="memberdetails-form">
              <label className="required">Email</label>
              <input
                type="text"
                className={
                  incomplete || !validateEmail() ? "input-incomplete" : ""
                }
                name="email"
                onChange={handleOnChange}
                value={selectedMember.email}
                disabled={
                  auth?.user?.role === "member" ||
                  auth?.user?.role === "secretary"
                }
                required
              />
            </div>
            <div className="memberdetails-form">
              <label className="required">Phone No</label>
              <input
                type="text"
                className={
                  incomplete || !validatePhoneNo() ? "input-incomplete" : ""
                }
                name="phone_no"
                onChange={handleOnChange}
                value={selectedMember.phone_no}
              />
            </div>
            <div className="memberdetails-form">
              <label>UPI id</label>
              <input
                type="text"
                name="upi_id"
                onChange={handleOnChange}
                value={selectedMember.upi_id}
              />
            </div>
          </motion.section>
          <motion.div
            className="memberdetails-footer"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            <AuthorizedContent roles={["admin", "super-admin"]}>
              <button
                className="memberdetails-formsubmit"
                onClick={handleDelete}
                disabled={loading}
              >
                {loading ? <Loading /> : "Delete"}
              </button>
            </AuthorizedContent>
            <button
              className="memberdetails-formsubmit"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <Loading /> : "Submit"}
            </button>
          </motion.div>
          <label className="memberdetails-header">Change password</label>
          <motion.section
            className="memberdetails"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="memberdetails-form">
              <label>Old password</label>
              <input type="password" name="oldPassword" ref={oldPassword} />
            </div>
            <div className="memberdetails-form">
              <label>Confirm old password</label>
              <input
                type="password"
                name="confirmPassword"
                ref={confirmPassword}
              />
            </div>
            <div className="memberdetails-form">
              <label>New Password</label>
              <input type="password" name="newPassword" ref={newPassword} />
            </div>
            <div className="memberdetails-form memberdetails-form-password-btn">
              <button
                className="memberdetails-formsubmit"
                onClick={handleChangePassword}
                disabled={loading}
              >
                {loading ? <Loading /> : "Change Password"}
              </button>
            </div>
          </motion.section>
          <label className="memberdetails-header">Change Profile Image</label>
          <motion.section
            className="memberdetails"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="memberdetails-form">
              <input
                type="file"
                name="file-7[]"
                id="file-7"
                className="memberdetails-inputfile memberdetails-inputfile-6"
                // data-multiple-caption="{count} files selected"
                // multiple
                onChange={(e) => {
                  const fileMb = e.target.files[0].size / 1024 ** 2;
                  console.log(e.target.files[0]);
                  if (fileMb > 2) {
                    console.log(fileMb);
                    toast("Please select a file less than 2MB.");
                  } else {
                    setImage(e.target.files[0]);
                    // toBase64(e.target.files[0]).then((res) => setImagebase64(res));
                  }
                }}
              />
              <label for="file-7">
                <span>{image?.name}</span>{" "}
                <strong>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="17"
                    viewBox="0 0 20 17"
                  >
                    <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" />
                  </svg>{" "}
                  Choose a file&hellip;
                </strong>
              </label>
            </div>

            <div className="memberdetails-form memberdetails-form-password-btn">
              <button
                className="memberdetails-formsubmit"
                onClick={uploadImage}
              >
                {loading ? <Loading /> : "Upload"}
              </button>
            </div>
          </motion.section>
        </>
      )}
    </>
  );
}

export default MemberProfile;
